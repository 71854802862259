
const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const blog = () => import(/* webpackChunkName: "blog" */ '@/pages/blog/blog');
const blogDetail = () => import(/* webpackChunkName: "blogDetail" */ '@/pages/blog/blogDetail');
export default [
    {
        path: '/blog',
        name: 'blog',
        meta: {
            keepAlive: true,
        },
        components: {
            default: blog,
            header:header,
            footer:footer,
        }
    },{
        path: '/blogDetail',
        name: 'blogDetail',
        meta: {
            keepAlive: true,
        },
        components: {
            default: blogDetail,
            header:header,
            footer:footer,
        }
    }
];
