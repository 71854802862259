export const service = {
    /*
     * 登录注册模块
     */ 
    api_phone_register:{//手机号注册
        url: 'login/smsregistered',
        method: 'post',//account（手机号）、 password（密码）、 code（手机验证码）
        site: 0,//[0：'api2/',]、[1:'']
        // noTrans: true,//json对象格式(勿删)
    },
    api_email_register:{//邮箱注册
        url: 'login/emailregistered',
        method: 'post',//account（邮箱）、 password（密码）、 code（邮箱验证码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_phone_login:{//手机号登录
        url: 'login/smslogin',
        method: 'post',//account（手机号）、 password（密码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_email_login:{//邮箱登录
        url: 'login/emaillogin',
        method: 'post',//account（邮箱）、 passsword（密码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_phone_forgetpass:{//手机号忘记密码
        url: 'login/smsforgetPassword',
        method: 'post',//account（手机号）、 password（密码）、code（短信验证码）、areacode（手机区号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_email_forgetpass:{//邮箱忘记密码
        url: 'login/emailforgetPassword',
        method: 'post',//account（邮箱）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_phone_sendcode:{//手机号短信验证码
        url: 'login/sendsmscode',
        method: 'post',//telno（手机号）、 type（类型： 1短信注册 2短信忘记密码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_email_sendcode:{//邮箱验证码
        url: 'login/sendemailcode',
        method: 'post',//email（邮箱）、 type（类型：  6邮箱注册 7邮箱忘记密码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_facebooklogin:{//facebook登录
        url: 'login/facebooklogin',
        method: 'post',//time（时间戳）、sign（签名）、id（facebook返回的平台唯一id）、nickname（昵称）、head_pic（头像路径）、access_token（access_token）、union_id（唯一id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goolelogin:{//goole登录
        url: 'login/goolelogin',
        method: 'post',//time（时间戳）、sign（签名）、id（facebook返回的平台唯一id）、nickname（昵称）、head_pic（头像路径）、access_token（access_token）、union_id（唯一id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    getWXToken:{//微信授权登录
        url: 'login/getwechatuserinfo',
        method: 'post',//code
        site: 0,//[0：'api2/',]、[1:'']
    },
    
    /*
     * 用户完善信息
     */
    api_user_infofrom:{//联系客服提交问题
        url: 'user/savebaseinfobytoken',
        method: 'post',//token（用户token）、family_name（姓）、name（名）、nationality（国籍）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_getinfobytoken:{//根据token获取用户信息
        url: 'user/getinfobytoken',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_fromaddresstoken:{//保存用户地址
        url: 'user/saveaddressbytoken',
        method: 'post',//token（用户token）、id（数据id 修改时必填）、country（国家 添加时必填）、city（城市 添加时必填）、info（详细地址 添加时必填）、family_name（姓 添加时必填）、name（名 添加时必填）
        // mobile（手机号 添加时必填）、is_default（是否为默认地址 1是 0否 默认0）、apartment（公寓、门牌号 添加时必填）、doorbell（门铃 添加时必填）
        // postcode（邮编 添加时必填）、is_add（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、is_edit（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_addresslist:{//用户地址列表
        url: 'user/getaddresslistbytoken',
        method: 'post',//token（用户token）、page（页数 默认1）、size（每页条数 默认10）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_addressdelete:{//删除用户地址
        url: 'user/deleteaddressbytoken',
        method: 'post',//token（用户token）、id（数据id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_addressdefault:{//默认用户地址
        url: 'user/getdefaultaddressbytoken',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_savecreditcard:{//保存用户银行卡
        url: 'user/savecreditcardbytoken',
        method: 'post',//token（用户token）、id（数据id 修改时必填）、card_number（信用卡号 添加时必填）、name（姓名 添加时必填）、
        // expiring_date（过期时间 添加时必填）、safe_code（安全码 添加时必填）、is_add（是否添加 1是 0否 默认0 is_add、is_edit二者其一必填 ）、
        // is_edit（是否修改 1是 0否 默认0 is_add、is_edit 二者其一必填）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_getcreditcardlist:{//获取用户信用卡列表
        url: 'user/getcreditcardlistbytoken',
        method: 'post',//token（用户token）、page（页数 默认1）、size（每页条数 默认10）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_deletecreditcard:{//删除用户信用卡
        url: 'user/deletecreditcardbytoken',
        method: 'post',//token（用户token）、id（数据id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_user_savebaseinfobytokenforcompany:{//保存企业基本信息
        url: 'user/savebaseinfobytokenforcompany',
        method: 'post',//token（用户token）、company_name（企业名称）、mobile（联系电话）、address（企业地址）、ein（税号）、electronic_invoice_number（电子发票号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    
    /*
     * 首页banner图
     */
    api_datalist_banner:{//banner列表
        url: 'data_list/getbannerlist',
        method: 'post',// type类型 （1 首页轮播图, 2 首页位置1, 3 首页位置2, 4 首页位置3）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_datalist_getzipcodebyprovince:{//根据省份查询邮编
        url: 'data_list/getzipcodebyprovince',
        method: 'post',//province（省份）、country （国家） 默认it
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_datalist_getprovincebyzipcode:{//根据邮编查询省份
        url: 'data_list/getprovincebyzipcode',
        method: 'post',//province（省份）、country （国家） 默认it
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_datalist_getcashdelivery:{//根据邮编查询省份
        url: 'data_list/getcashdelivery',
        method: 'post',//token(用户token)
        site: 0,//[0：'api2/',]、[1:'']
    },


    
    // /
    /*
     * 联系客服提交问题
     */ 
    api_server_from:{//联系客服提交问题
        url: 'server/newinfo',
        method: 'post',//telno（电话）、info（请求信息）、toekn（用户token 非必填）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_server_cooperation:{//获取合作信息初始化信息
        url: 'server/getintdataforcooperation',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_server_newcooperation:{//获取合作信息初始化信息
        url: 'server/newcooperation',
        method: 'post',//参数巨多
        site: 0,//[0：'api2/',]、[1:'']
        isFormData: true
    },
    /*
     * 国家列表
     */
    api_datalist_countrylist:{//国家列表
        url: 'data_list/getCountryList',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    /*
     * 商店列表
     */
    api_datalist_shoplist:{//商店列表
        url: 'data_list/getShopList',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    /*
     * 订单状态列表
     */
    api_datalist_orderstatuslist:{//订单状态列表
        url: 'data_list/getorderstatuslist',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_datalist_getcurrencylist:{//获取币种列表
        url: 'data_list/getcurrencylist',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    /*
     * 商品分类数据
     */
    api_goods_levelone:{//商品顶级数据（列表页的左侧tab分类）
        url: 'goods/gettypelevel1',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_leveltwo:{//商品二级数据
        url: 'goods/gettypelevel2',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_levelone_child:{//根据顶级类型获取二级类型（导航商品下的商品数据）
        url: 'goods/gettypelevel2bylevel1',
        method: 'post',//typeid（顶级类型id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_shoplist:{//根据条件获取产品列表（首页和商品列表页）
        url: 'goods/getlistbywhere',
        method: 'post',//typeid（顶级类型）、typetwoid（二级类型）、ishome（是否首页推荐）、page（页数）、size（每页条数默认10条值为0无分页）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_shopinfobyid:{//根据id获取商品详情（商品详情页的商品介绍详情数据）
        url: 'goods/getinfobyid',
        method: 'post',//id（产品id）、iscompany（是否企业身份 1是 0 否）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_shopgsinfobyid:{//根据产品id获取产品规格（商品详情页的商品规格数据）
        url: 'goods/getgsinfobyid',
        method: 'post',//id（产品id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_goods_shopdiscounts:{//根据条件获取折扣商品
        url: 'goods/getdiscountslistbywhere',
        method: 'post',//typeid（一级类型id）、typetwoid（二级类型id）、ishome（是否首页指定）、page（页数）、size（每页条数）、iscompany（是否为企业身份 1是0否）
        site: 0,//[0：'api2/',]、[1:'']
    },
    
    /*
     * 购物车
     */
    api_order_editcart:{//购物车编辑
        url: 'order/editcart',
        method: 'post',//token（用户token）、goods_id（商品id）、goods_no（商品数量）、is_delete（是否删除 1是 0否）、is_add（是否添加 1是 0否）、is_edit（是否 编辑 1是 0否）goods_spect_id（产品规格id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getcartlistbytoken:{//获取购物车列表
        url: 'order/getcartlistbytoken',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_cleancart:{//清空购物车列表
        url: 'order/cleancart',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_createorder:{//创建订单
        url: 'order/createorder',
        method: 'post',//token（用户token）、cart_id（购物车行id数组[1,2]）、country（与获取国家列表中属性key值相同 根据不同国家后台匹配不同满额包邮费用）
        site: 0,//[0：'api2/',]、[1:'']
        isFormData: true
    },
    api_order_getorderlist:{//订单列表
        url: 'order/getorderlist',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getaftersale:{//获取售后状态列表
        url: 'order/getaftersale',
        method: 'post',//token（用户token）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_setadressOrder:{//设置订单地址
        url: 'order/setadressOrder',
        method: 'post',//token（用户token）、orderno（用户订单号）、addressid（用户地址id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_ordernoforpay:{//获取订单信息用于支付
        url: 'order/getorderinfobyordernoforpay',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getorderinfo:{//获取订单详情
        url: 'order/getorderinfo',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getstatuslist:{//获取用户订单状态列表
        url: 'order/getstatuslist',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_gettype2list:{//获取订单支付类型
        url: 'order/gettype2list',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getpricetype:{//获取支付形式列表
        url: 'order/getpricetype',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_payorder:{//支付订单
        url: 'order/payorder',
        method: 'post',//token（用户token）、orderno（订单编号）、pricetype（支付方式 1微信 2支付宝 3visa 4线下支付）、isMobile（是否为手机端 1是 0否）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_orderquery:{//查询订单支付状态
        url: 'order/orderquery',
        method: 'post',//token（用户token）、orderno（订单编号）、transactionnumber（sILKPAY平台编号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_createOrderByGoodsid:{//创建订单根据商品
        url: 'order/createOrderByGoodsid',
        method: 'post',//token（用户token）、goodsid（商品id）、goodsnum（商品数量）、spectid（规格id）、country（国家编码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getpackagemailamount:{//获取包邮金额
        url: 'order/getpackagemailamount',
        method: 'post',//country（国家编码）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_addfeedback:{//添加订单商品评论
        url: 'order/addfeedback',
        method: 'post',//接口请求数据格式 { “token”:用户token, "orderno":订单号, "datalist":[ { "ogid":订单商品id, "score":评分 1到5, "content":评价内容 } ] } 
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getlistforhome:{//首页获取商品评论
        url: 'order/getlistforhome',
        method: 'post',//size（条数）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getlistbygoodsidtype:{//根据商品id和类型获取商品评论
        url: 'order/getlistbygoodsidtype',
        method: 'post',//goodsid（商品id）、type(评价类型)、page（页数）、size（每页条数）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getfeedbackbygoodsidtypelist:{//根据产品id获取评论类型
        url: 'order/getfeedbackbygoodsidtypelist',
        method: 'post',//goodsid（商品id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_applyrefund:{//订单退款申请
        url: 'order/applyrefund',
        method: 'post',//token（用户token）、orderno（用户订单号）、season（退款原因）、ogids[0]（订单商品id）、money（退款金额 用于数据校验）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getaftersaleinfo:{//查询售后信息
        url: 'order/getaftersaleinfo',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getaftersaletypelist:{//获取退款类型
        url: 'order/getaftersaletypelist',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_confirmorder:{//确认收获
        url: 'order/confirmorder',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getpostage:{//获取系统保底邮费
        url: 'order/getpostage',
        method: 'post',//无参数
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_gettracking:{//查询物流轨迹
        url: 'order/gettracking',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_deleteorder:{//订单删除
        url: 'order/deleteorder',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_order_getlogisticsinfo:{//订单删除
        url: 'order/getlogisticsinfo',
        method: 'post',//token（用户token）、orderno（用户订单号）
        site: 0,//[0：'api2/',]、[1:'']
    },
    /*	
    * 文章
    */
    api_article_info:{//根据id获取详情
        url: 'article/getinfobyid',
        method: 'post',//id（数据id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_article_list:{//根据条件获取列表
        url: 'article/getlistbywhwere',
        method: 'post',//id（数据id）、typeid（一级类型id）、typetwoid（二级类型id）、page（页数 默认1）、size（每页条数 默认10）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_article_twotype:{//根据一级类型id获取二级类型
        url: 'article/gettypebyid',
        method: 'post',//id（一级类型id）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_article_comment:{//添加评论列表
        url: 'article/getcommentlistbyid',
        method: 'post',//id（数据id）、page（页数 默认1）、size（每页条数 默认10）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_article_fromcomment:{//新增评论
        url: 'article/newcomment',
        method: 'post',//articleid（文章id）、token（用户token）、content（评价内容）
        site: 0,//[0：'api2/',]、[1:'']
    },
    api_article_addnum:{//增加文章数量
        url: 'article/addnum',
        method: 'post',//id（文章id）、type（增加类型 1 分享 2 浏览 3 评论）
        site: 0,//[0：'api2/',]、[1:'']
    },
};
