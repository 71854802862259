import axios from 'axios'
axios.defaults.timeout=30000
//post 请求头
axios.defaults.headers.post['Content-Type']='application/json;charset=UTF-8;text/plain';
//请求拦截
axios.interceptors.request.use(config=>{
    return config;
},
error =>{
    return Promise.error(error);
});
axios.interceptors.response.use(
    response =>{
        if(response.status == 200){
            return Promise.resolve(response)
        }else{
            return Promise.reject(response)
        }
    },
    error =>{
        console.log(error, 'error')
        if(error.response.status){
            console.log(error)
        }
        return Promise.reject(error.response)
    }
)

export function get(url,params){
    return new Promise((resolve,reject)=>{
        axios.get(url,{params:params}).then(res=>{
            resolve(res.data);
        }).catch(err=>{
            if(!err.response){
                console.log('get请求错误')
            }else{
                reject(err.data);
                console.log(err.response,'异常')
            }
        })
    })
}

export function post(url,params){
    return new Promise((resolve,reject)=>{
        axios.post(url,params).then(res=>{
            console.log(res)
            resolve(res.data)
        }).catch(err=>{
            console.log(err)
            reject(err.data)
        })
    })
}
