<template>
    <!-- 问客服提示弹窗 -->
    <b-modal size="sm" centered v-model="helpStatus" header-class="justify-content-center" hide-footer :title="$t('message.wenkf')" @hidden="getClose">
        <div class="login-group"><input type="text" class="password" v-model="paramsDataObj.name" :placeholder="'*'+$t('message.nameuser')"/></div>
        <div class="login-group"><input type="text" class="password" v-model="paramsDataObj.email" :placeholder="'*'+$t('message.email')"/></div>
        <div class="login-group"><input type="text" class="" v-model="paramsDataObj.telno" :placeholder="$t('message.mobilenum')"/></div>
        <div class="login-group-area">
            <textarea v-model="paramsDataObj.info" :placeholder="$t('message.areinfo')"></textarea>
        </div>
        <div class="login-group-btn" @click="getSubmit">{{$t('message.submitbtn')}}</div>
    </b-modal>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,getLocalItem } from '@/untils/common';
export default {
    name:'helpStatus',
    computed:{
        helpStatus: {
            get () {
                return this.$store.state.data.helpStatus;
            },
            set (val) {
                this.$store.commit('setHelpStatus', val)
            }
        },
        language() {
            var params = {
                "lang":this.$store.state.data.languageNameStatus.key?this.$store.state.data.languageNameStatus.key:(localStorage.lang?localStorage.lang:'cn')
            };
            return params;
        },
    },
    watch:{
        language:function() {
            this.getLanguage();
        },
    },
    data(){
        return{
            token:'',//token
            paramsDataObj:{
                name:'',//姓名
                email:'',//邮箱
                telno:'',//	电话
                info:'',//请求信息
            },
            languageObj:{},//多语言
        }
    },
    methods:{
        getLanguage(){
            if(this.language.lang=='cn'){
                this.languageObj = {
                    "usernametip":"请输入您的姓名",
                    "emailtip":"请输入您的邮箱",
                    "teletip":"请输入您的电话号",
                    "problemtip":"请输入您的问题描述",
                    "emailrighttip":"请输入正确的邮箱",
                    "phonerighttip":"请输入正确的手机号",
                }
            }else if(this.language.lang=='en'){
                this.languageObj = {
                    "usernametip":"enter your name",
                    "emailtip":"enter your email",
                    "teletip":"enter your phone number",
                    "problemtip":"enter your problem description",
                    "emailrighttip":"Please enter the correct email address",
                    "phonerighttip":"Please enter the correct mobile number",
                }
            }else if(this.language.lang=='it'){
                this.languageObj = {
                    "usernametip":"inserisci il tuo nome",
                    "emailtip":"inserisci la tua email",
                    "teletip":"inserisci il tuo numero di telefono",
                    "problemtip":"description	Descrizione del problema",
                    "emailrighttip":"Inserisci l'indirizzo email corretto",
                    "phonerighttip":"Inserisci il numero di cellulare corretto",
                }
            }
        },
        // 关闭弹窗
        getClose(){
            this.$store.commit('setHelpStatus',false);
        },
        // 提交
        getSubmit(){
            this.getLanguage();
            if(this.paramsDataObj.name==''){
                ElementUI.Message({
                    message:this.languageObj.usernametip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.paramsDataObj.email==''){
                ElementUI.Message({
                    message:this.languageObj.emailtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!jsReg.email.test(this.paramsDataObj.email)){
                ElementUI.Message({
                    message:this.languageObj.emailrighttip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if (this.paramsDataObj.telno=='') {
                ElementUI.Message({
                    message:this.languageObj.teletip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!jsReg.number.test(this.paramsDataObj.telno)){
                ElementUI.Message({
                    message:this.languageObj.phonerighttip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.paramsDataObj.info==''){
                ElementUI.Message({
                    message:this.languageObj.problemtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else{
                let params = this.paramsDataObj;
                params.toekn = this.token,
                this.$http.api_server_from(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data&&res.data.sid){
                                this.paramsDataObj = {};
                                // this.paramsDataObj.telno = '';
                                // this.paramsDataObj.email = '';
                                ElementUI.Message({
                                    message:res.message,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                this.$store.commit('setHelpStatus',false);
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
    },
    created(){
        
    },
    mounted(){
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
        }
    }
}
</script>
<style lang="scss" scoped>
.van-toast--html .van-toast__text, .van-toast--text .van-toast__text{
    color: #fff !important;
}
.van-toast .van-toast__text{
    color: #fff !important;
}
.login-group{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
    select,input{
        outline:none;
        border:none;
        padding:0 10px;
        background: transparent;
    }
    .password{
        width: 100%;
    }
}
.login-group-area{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    margin-bottom: 20px;
    textarea{
        height:100px;
        width: 100%;
        outline:none;
        border:none;
        padding:10px;
        background: transparent;
        resize: none;
    }
}
.login-group-btn{
    cursor: pointer;
    background: #222;
    color: #fff;
    text-align: center;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
}
// 模态窗样式调整设计图模样
@media (min-width: 576px){
    /deep/.modal-sm {
        max-width: 500px; 
    }
}
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 35px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-bottom:20px;
    padding-left:0px;
    padding-right:0px;
    border-bottom:none;
    .modal-title{
        width: 100%;
        text-align: center;
        font-size:22px;
    }
    .close{
        font-size: 28px;
        font-weight: normal;
        padding:0 ;
        margin: -1rem -1rem -1rem auto;
    }
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
</style>