<template>
	<van-overlay :show="isShow" :z-index=99999 :custom-style="{
			background: 'rgb(0, 0, 0, 0.6)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}">
		<van-loading type="spinner" size="32px" color="#fff">
			<span style="color:#fff;font-size:16px;">{{ title || '加载中···' }}</span>
		</van-loading>
	</van-overlay>
</template>

<script>
	import {Overlay,Loading} from 'vant'
    export default {
        name: "Loading",
		components:{
          [Overlay.name]:Overlay,
          [Loading.name]:Loading,
		},
    }
</script>

<style scoped>

</style>
