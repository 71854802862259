<template>
    <!-- 删除提示弹窗 -->
    <b-modal size="sm" centered v-model="delStatus" hide-footer :title="$t('message.tiptitle')" @hidden="getClose">
        <div class="d-block text-center">
            <p style="padding:30px 0;">{{$t('message.comfirdel')}}</p>
        </div>
        <b-button block @click="toggleModal">{{$t('message.firm')}}</b-button>
    </b-modal>
</template>
<script>
import ElementUI from 'element-ui';
import {getLocalItem,delLocalItem,yooz_lang} from '@/untils/common';
export default {
    name:'delStatus',
    inject:['reload'],
    computed:{
        delStatus: {
            get () {
                return this.$store.state.data.delStatus;
            },
            set (val) {
                this.$store.commit('setDelStatus', val)
            }
        }
    },
    data(){
        return{
            languageName:'',
            languageObj:{},
        }
    },
    methods:{
        // 关闭弹窗
        getClose(){
            this.$store.commit('setDelStatus',false);
        },
        toggleModal(){
            let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
            let dataStatus = this.$store.state.data.delItemStatus;
            // cartone 单个购物车商品删除
            // cartclear 购物车商品清空
            if(dataStatus.deltype=='cartone'){
                let params = {
                    token:token,//（用户token）、
                    goods_id:dataStatus.goods_id,//（商品id）、
                    goods_no:dataStatus.goods_no,//（商品数量）、
                    is_delete:1,//（是否删除 1是 0否）、
                    is_add:0,//（是否添加 1是 0否）、
                    is_edit:1,//（是否 编辑 1是 0否）
                    goods_spect_id:dataStatus.goods_spect_id//(商品规格id)
                }
                this.$http.api_order_editcart(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.reload();
                                this.$store.commit('setDelStatus',false);
                                ElementUI.Message({
                                    message:this.languageObj.delsuccess,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else if(dataStatus.deltype=='cartclear'){
                this.$http.api_order_cleancart({
                    token:token,
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.reload();
                                this.$store.commit('setDelStatus',false);
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        }
    },
    mounted(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "delsuccess":"删除成功",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "delsuccess":"Successfully deleted",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "delsuccess":"Eliminato con successo",
            }
        }
    }
}
</script>