import enLocale from "element-ui/lib/locale/lang/en";
const en = {
    message:{
        "loading":"Loading",
        "header":"Select your language",
        "nav-head":"You must be 18+. It is illegal to sell to minors.",
        "nav":"37 Vape products contain nicotine which is not suitable for minors.",
        "navigazione":"Free delivery for orders above €",
        "navigazionemap":"Store location",
        "home":"Home",
        "shop":"Store",
        "special":"Special offers",
        "aboutus":"About us",
        "support":"Support",
        "cooperation":"Partnership opportunities",
        "blog":"Follow us",
        "accountinfo":"My account",
        "myorder":"My orders",
        "outlog":"Log out and log in",
        "cashop":"View all",
        "whearbay":"Where can I buy it",
        "moreinfo":"More info",
        "nowshop":"Buy now",
        "newweet":"New flavours",
        "allcomment":"Ratings",
        "usstart":"Our 4 and 5 stars ratings",
        "linkus":"Follow us on social media",
        "guardianplan":"Youth protection and guardian programme",
        "illicittrade":"Fighting illicit trades",
        "servecenter":"Support center",
        "warrantybill":"Insurance",
        "seeproblem":"FAQ",
        "shopfixed":"Store locations",
        "dischild":"Sale to minors is prohibited",
        "discenten":"The website is authorized by the Italian State for the online sale of liquids for e-cigarettes.",
        "disfoot":"PIVA: 11632470966",
        "footnum":"Terms and Conditions",
        "footext":"Privacy policy",
        "specialtxt1":"50% off all products",
        "specialtxt2":"Products may be sold due to shelf life, sporadic inventory, packaging updates and other reasons.",
        "specialtxt3":"We do not exchange or refund the prodcts, please place your order carefully.",
        "specialshop":"Sale",
        "color":"Colours",
        "num":"Quantity",
        "addcar":"Add to cart",
        "search":"Search",
        "searchsee":"Search commonly asked question",
        "searchend":"Results",
        "cooperationtxt1":"Thank you for contacting 37 Vape. We will reply you within 3 working days",
        "cooperationtxt2":"Fields with * are required",
        "allname":"Full name",
        "age":"Age",
        "occupation":"Profession",
        "industry":"Sector",
        "webint":"Company Wensite",
        "status":"Status",
        "country":"Country",
        "firstarr1":"Select collaboration method (multiple choices)",
        "firstarr2":"Type of entry （multiple choice）",
        "firstarr3":"Please enter your type of occupancy",
        "firstarr4":"Number of retail stores/affiliates",
        "firstarr5":"Do you own retail stores？",
        "firstarr6":"Business structure",
        "movephone":"Phone contact",
        "emialphone":"Email",
        "phoneinfo":"Contact details",
        "coeatxt1":"Would you consider investing in or opening a 37 Vape exclusive store?",
        "wherecity":"City",
        "wherewhy":"Why choosing 37 Vape",
        "wherename":"Are you recommended by a 37 Vape employee? If so, please provide their full names.",
        "wherecheck1":"Subscribe to receive newsletters/marketing information.",
        "wherecheck2":"We take your privacy very seriously and will only use your personal information to provide the products and services you request. To continue, please read and agree to 37 Vape's Privacy Policy and Terms and Conditions.",
        "shareto":"Sharing",
        "shoptxt":"Product description",
        "commentxt":"Reviews",
        "ucom":"Customer reviews",
        "recoment":"Add your review",
        "priceshop":"price",
        "tbmore":"Operation",
        "haspost":"Shipping fees included",
        "loginfo":"Shipping information",
        "delorder":"Cancel order",
        "shoinfo":"Assistance",
        "orinfo":"Order details",
        "orddetail":"Order information",
        "shoshopinfo":"Delivery information",
        "shoppeple":"Recipient",
        "shoaddress":"Recipeint address",
        "ortime":"Order date",
        "pytime":"payment date",
        "ftime":"Shipping date",
        "fintime":"Completion date",
        "orstatus":"Status",
        "nowpay":"Pay now",
        "shopmoney":"Total",
        "postmoney":"Shipping fees",
        "paymoeny":"Total",
        "ermovepost":"minus shipping fees",
        "tmoney":"Total refunded",
        "logis":"Logistics",
        "logising":"In transit",
        "logisnum":"Shipping number",
        "logcompay":"Shipping company",
        "fpople":"Sender",
        "fadress":"Sender address",
        "tksuccess":"refund sucessful",
        "tkwhy":"Refund reasons",
        "tmoneys":"Total refunded",
        "tkbz":"Term and conditions for refunds",
        "palytime":"Application time",
        "thaddress":"Return address",
        "quyinfo":"View order details",
        "state0":"Order status",
        "state1":"the product has been added, waiting for the buyer to pay",
        "state2":"Buyer has paid, waiting for merchant to ship",
        "state3":"Merchant has shipped, waiting for buyer's confirmation",
        "state4":"Transaction Successful",
        "state5":"application has been submitted, waiting for the merchant to process",
        "state6":"order closed, refund successful",
        "state7":"refund sucessful",
        "state8":"Please wait for the merchant to process",
        "state9":"Please return the goods according to the return address given.Please add your account number in the package when returning the goods. we will review the refund after receiving the goods.",
        "logintitle":"Log in to your account",
        "regtitle":"Create a new account",
        "phonenum":"Phone number",
        "emailnum":"E-mail",
        "passnum":"password",
        "invitationnum":"Invitation code",
        "othtype":"Other login methods",
        "loginbtn":"Log in",
        "othregtype":"Other registration methods",
        "regbtn":"register",
        "newuser":"I am a new user",
        "fgetpass":"Forgot password",
        "ycode":"verification code",
        "getcode":"get verification code",
        "hasacc":"have an account",
        "emailfind":"Email recovery",
        "phonefind":"phone number retrieval",
        "nowfind":"Get it back now",
        "bindphone":"Bind mobile number",
        "addinfo":"Perfect information",
        "nowbind":"Access now",
        "name":"name",
        "surname":"surname",
        "country":"Country of Citizenship",
        "firm":"Confirm",
        "email":"email",
        "agetxt1":"Please verify your age",
        "agetxt2":"Product contains nicotine and is not suitable for minors.",
        "agetxt3":"Please confirm your age to continue",
        "agetxt4":"I'm 18+",
        "agetxt5":"I'm not yet 18",
        "chioselang":"Please select language",
        "wenkf":"ask customer service",
        "nameuser":"Name",
        "mobilenum":"phone number",
        "areinfo":"Problem Description",
        "submitbtn":"submit",
        "mycar":"my shopping cart",
        "emptycar":"empty cart",
        "allshop":"all products",
        "letpay":"Buy now",
        "todopay":"continue to buy",
        "checkorderinfo":"Fill in and check the order information",
        "moenytotal":"Subtotal",
        "postmoneys":"Shipping fees",
        "fullpay":"Full",
        "freepay":"free shipping",
        "paymoenyed":"Total payable",
        "plan":"37 Vape Program",
        "newaddress":"use the new address",
        "conuycity":"country / region",
        "address":"address",
        "doornum":"Apartment, house number, etc.",
        "doorling":"doorbell",
        "city":"City",
        "postcode":"zip code",
        "telep":"Telephone",
        "suborder":"Submit",
        "update":"Revise",
        "pay":"pay",
        "paytotal":"pay now",
        "paysuccess":"payment successful",
        "quyorderinfo":"View order details",
        "orinfotip":"This platform will not ask you to provide bank card information or pay additional fees for any reason, please beware of phishing links or fraudulent calls",
        "ordernum":"order number",
        "sshopinfo":"delivery information",
        "shopnames":"product name",
        "paytype":"payment method",
        "myuser":"My account",
        "userinfo":"account information",
        "hi":"hi",
        "addressfrom":"Address management",
        "allmpney":"Total amount",
        "postalcode":"postal code",
        "destinatario":"consignee",
        "order":"order",
        "fhinfo":"Shipping Information",
        "shinfo":"After sales details",
        "shshop":"After sales goods",
        "reg1":"I have read and agree to 37 Vape's",
        "and":"and",
        "usearicle":"Terms of Use",
        "province":"province",
        "welcom":"Welcome to 37 Vape",
        "nodata":"No relevant data",
        "wchartpay":"Pay by WeChat",
        "zfbpay":"pay by AliPay",
        "xykpay":"pay by credit card",
        "xxpay":"Offline payments",
        "zxpay":"Online Payment",
        "hdfkpay":"cash on delivery",
        "carshop":"shopping cart",
        "carnodata":"Purchase now",
        "coderest":"Log in later",
        "added":"New & Edit",
        "addsub":"Add new address / OK",
        "defaultcheck":"set as Default",
        "defaultaddress":"default address",
        "newaddress":"add new address",
        "edits":"edit",
        "dels":"delete",
        "seemore":"see more",
        "shopcomment":"Product rating",
        "subcommont":"Post a review",
        "writehert":"Write down your purchase experience and usage experience",
        "seelog":"View order details",
        "comfiresh":"confirm the receipt of goods",
        "showstep":"After-sales progress",
        "all":"all",
        "nolist":"No data",
        "ruse":"created",
        "writpaylab":"Pending payment",
        "writfh":"to be delivered",
        "writshlab":"pending receipt",
        "successlab":"completed",
        "showlab":"Sold",
        "solding":"After sale",
        "writstate1":"Cash on delivery, waiting for the merchant to ship",
        "writstate2":"After the merchant receives the goods, the refund will be returned to the payment account, please be patient",
        "subforms":"submit application",
        "allcheck":"select all",
        "seeall":"view all",
        "findshop":"Find a store",
        "homepro1":"Your online store marking the next-generation e-cigarettes",
        "homepro2":"RELX Palm garden, fragrant fruit, menthol and more by 37 Vape pods",
        "homepro3":"37 Vape is your online resource for purchasing premium vaping and next-generation vaping equipment. We use the most modern production technology and offer you innovative equipment. Our products combine sleek, modern design with cutting-edge technology to bring you the best vaping experiences",
        "promore":"Product Series",
        "shoplist":"Store list",
        "shopfixed":"Store Locator",
        "langage":"language",
        "linktype":"contact details",
        "comments":"Comment",
        "share":"Share",
        "uspromise":"Our commitment",
        "usoutjoin":"Our story",
        "usoutjoins":"Our Story",
        "zzauto":"Certification",
        "usebook":"User Manual",
        "shoptype":"37 Vape products",
        "usernametip":"enter your name",
        "emailtip":"enter your email",
        "teletip":"enter your phone number",
        "problemtip":"enter your problem description",
        "passwordtip":"enter your password",
        "phonetip":"enter your mobile number",
        "phonecodetip":"enter your SMS verification code",
        "seechecktip":"Please read and agree to 37 Vape's Privacy Policy and Terms of Use",
        "allnametip":"enter your full name",
        "agetip":"enter your age",
        "occupationtip":"enter your occupation",
        "industrytip":"enter your industry",
        "citytip":"enter your city",
        "statustip":"enter your status",
        "countrytip":"enter your country",
        "cooptypetip":"select your preferred cooperation method",
        "jiontypetip":"select the type of occupancy",
        "companytip":"enter how many retail stores/affiliate links you have",
        "shoptip":"select if you own a retail store",
        "gmtip":"select a size",
        "mobiletip":"enter mobile phone",
        "eemailtip":"enter contact email",
        "oneshoptip":"select if you would consider investing or opening a 37 Vape exclusive store",
        "checkinfotip":"Please agree to subscribe to receive newsletters/marketing information",
        "checkwheretip":"Please read and agree to 37 Vape's Privacy Policy and Terms and Conditions",
        "numbertip":"Quantity is below the range",
        "carsuccesstip":"successfully Added to Cart",
        "logintip":"please log in first",
        "areatip":"select a country",
        "provincetip":"enter province",
        "nametip":"enter your name",
        "lasnametip":"enter your last name",
        "addresstip":"enter your address",
        "apartmenttip":"enter the apartment, house number, etc.",
        "doortip":"enter the doorbell",
        "postcodetip":"enter zip code",
        "telepstip":"enter your phone",
        "updatetip":"Modify the address successfully",
        "tkshoptip":"Please select a refund item",
        "searontip":"Please select a refund reason",
        "tkdetailtip":"Please enter refund instructions",
        "prev":"previous page",
        "next":"next page",
        "delsuccess":"Successfully deleted",
        "wcharttitle":"Please use wechat to scan",
        "waptitle":"Show order summary",
        "comfirdel":"Are you sure you want to delete?",
        "tiptitle":"Tips",
        "removenumber":"The quantity cannot be less",
        "emptydel":" Are you sure you want to empty the shopping cart products?",
        "ycan":"You can",
        "m":"€",
        "mpay":"euro",
        "showstate1":"To be reviewed by the merchant",
        "showstate2":"To be returned by the buyer",
        "showstate3":"To be received by merchants",
        "showstate4":"To be paid by the merchant",
        "showstate5":"After sales completion",
        "showstate6":"After sales rejection",
        "showstate7":"After sales shutdown",
        "hide":"Put away",
        "companyname":"Enterprise name",
        "companytitle":"Enterprise name",
        "companynum":"Enterprise tax number",
        "companyaddress":"Business address",
        "companymobile":"contact number",
        "companyenum":"Electronic invoice No",
        "shouxufei":"(€",
        "shouxufei2":" payment commission)",
    },
    ...enLocale
}
export default en;