const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const users = () => import(/* webpackChunkName: "users" */ '@/pages/users/users');//账户信息wap
const myaddressadd = () => import(/* webpackChunkName: "myaddressadd" */ '@/pages/users/myaddressadd');//地址新增wap
const passfind = () => import(/* webpackChunkName: "passfind" */ '@/pages/users/passfind');//密码找回
const myself = () => import(/* webpackChunkName: "myself" */ '@/pages/users/myself');//账户信息
const myaddress = () => import(/* webpackChunkName: "myaddress" */ '@/pages/users/myaddress');//地址管理
const myorder = () => import(/* webpackChunkName: "myorder" */ '@/pages/users/order/myorder');//订单管理
const myorderinfo = () => import(/* webpackChunkName: "myorderinfo" */ '@/pages/users/order/myorderinfo');//订单详情
const myordershowinfo = () => import(/* webpackChunkName: "myordershowinfo" */ '@/pages/users/order/myordershowinfo');//售后详情
const logistics = () => import(/* webpackChunkName: "logistics" */ '@/pages/users/order/logistics');//物流详情
const uinfo = () => import(/* webpackChunkName: "uinfo" */ '@/pages/users/uinfo');//绑定手机和完善信息
const evaluate = () => import(/* webpackChunkName: "evaluate" */ '@/pages/users/order/evaluate');//评价
// component:()=>import(/* webpackChunkName: "index" */ '../components/page/Main.vue'),
//友情链接
export default [
    {
        path: '/users',
        name: 'users',
        meta: {
            keepAlive: true,
        },
        components: {
            default: users,
            header:header,
            footer:footer,
        }
    },{
        path: '/passfind',
        name: 'passfind',
        meta: {
            keepAlive: true,
        },
        components: {
            default: passfind,
            header:header,
            footer:footer,
        }
    },{
        path: '/myaddressadd',
        name: 'myaddressadd',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myaddressadd,
            header:header,
            footer:footer,
        }
    },{
        path: '/myself',
        name: 'myself',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myself,
            header:header,
            footer:footer,
        }
    },{
        path: '/myaddress',
        name: 'myaddress',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myaddress,
            header:header,
            footer:footer,
        }
    },{
        path: '/myorder',
        name: 'myorder',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myorder,
            header:header,
            footer:footer,
        }
    },{
        path: '/myorderinfo',
        name: 'myorderinfo',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myorderinfo,
            header:header,
            footer:footer,
        }
    },{
        path: '/myordershowinfo',
        name: 'myordershowinfo',
        meta: {
            keepAlive: true,
        },
        components: {
            default: myordershowinfo,
            header:header,
            footer:footer,
        }
    },{
        path: '/logistics',
        name: 'logistics',
        meta: {
            keepAlive: true,
        },
        components: {
            default: logistics,
            header:header,
            footer:footer,
        }
    },{
        path: '/uinfo',
        name: 'uinfo',
        meta: {
            keepAlive: true,
        },
        components: {
            default: uinfo,
            header:header,
            footer:footer,
        }
    },{
        path: '/evaluate',
        name: 'evaluate',
        meta: {
            keepAlive: true,
        },
        components: {
            default: evaluate,
            header:header,
            footer:footer,
        }
    }
];
