<template>
    <div id="app">
        <router-view name="header"/>
        <router-view v-if="isShow"/>
        <router-view name="footer"/>
        <loginStatus></loginStatus>
        <helpStatus></helpStatus>
        <tipleStatus></tipleStatus>
        <delStatus></delStatus>
    </div>
</template>
<script>
// import ElementUI from 'element-ui';
// import myRate from '@/components/commons/rates.vue';
// import {setLocalItem,getLocalItem} from '@/untils/common';
// import Header from '@/components/header/header.vue';//头部及导航部分组件
// import Footer from '@/components/footer/footer.vue';//底部组件
import loginStatus from '@/components/popup/login.vue';//登录注册弹窗
import helpStatus from '@/components/popup/help.vue';//问客服弹窗
import tipleStatus from '@/components/popup/tiple.vue';//核实年龄弹窗
import delStatus from '@/components/popup/del.vue';//删除提醒弹窗
export default {
    name: 'app',
    components: {
        // Header,
        // Footer,
		loginStatus,
        helpStatus,
        tipleStatus,
        delStatus
 	},
    provide(){
        return{
            reload:this.reload
        }
    },
    data(){
        return{
            isShow:true
        }
    },
    methods:{
        reload(){
            this.isShow=false;
            this.$nextTick(()=>{
                this.isShow=true;
            });
        }
    },
    created(){
        // 判断是否是18岁+
        let yooz_age = localStorage.yooz_age?localStorage.yooz_age:'';
        if(!yooz_age){
            this.$store.commit('setTipleStatus',true);
        }
    }
}
</script>
<style lang="scss">
html,body {
    height:100%;
}
#app {
    font-family: 'Microsoft-yahei', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width:100%;
    height:100%;
}

</style>