import * as Reg from './reglist';
import wx from 'weixin-js-sdk';
import ElementUI from 'element-ui';
// import wx from 'weixin-js-sdk'
// export const yooz_url = 'https://www.yoozitaly.com/';//语言数据下的国旗图片（测试）
// export const yooz_url = 'http://yoozi.txunda.com:81'//:81图片地址//
// export const yooz_url = 'https://yoozh5.txunda.com/';//测试域名
export const yooz_login_url = 'https://www.37vape.com/';//(new)//
export const yooz_url = 'https://admin.37vape.com/';//(new)//
export const yooz_lang = 'it';//默认语言(cn,en,it)
export const yooz_langage = 'it-rit';//默认语言(zh-cn,en-us,it-rit)
export const config={
    // base_url:'http://yoozi.txunda.com:81',//:81图片地址（测试）
    base_url:'https://admin.37vape.com/',//(new)
    // base_url:'https://admin.yoozitaly.com',//(old)
}
export const urlPrefix = {
    0:'api2/',
}
export const urlfix = {
    prop:'api2/',
}
export const web_inter = {
    facebook:{
        client_id:'301546955432179',
        client_secret:'e770ae2bb5737aa0fe9892a249e4bf92',
    },
    google:{
        client_id:'680460682360-db1lfshm428pt371cl02r7l5qp8i4d4u.apps.googleusercontent.com',
        client_secret:'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',
        key:'AIzaSyB9GWUSCxPAZSrWqdtsUsYbFjsDN6Afx2c',
        google_url : yooz_login_url+'googleoauth',
        google_scope : 'https://www.googleapis.com/auth/userinfo.email',
    },
    wchart:{
        client_id:'wxfa34208611ccb881',
        client_secret:'a71876b17e005c2fd77f3b22080a9923',
        wchart_url : yooz_login_url+'wchartoauth',
    },
    instagram:{
        web_url:'https://www.instagram.com/'
    },
    languageList:[
        {mobile_area_code:'39'},
        {mobile_area_code:'33'},
        {mobile_area_code:'31'},
        {mobile_area_code:'32'},
        {mobile_area_code:'34'},
        {mobile_area_code:'351'},
        {mobile_area_code:'352'},
        {mobile_area_code:'41'},
        {mobile_area_code:'44'},
        {mobile_area_code:'49'},
        {mobile_area_code:'353'},
    ],
}
/*
 https://open.weixin.qq.com/connect/qrconnect
 ?appid=wxea3axxxxx41a91a
 &redirect_uri=http://20y85987b7.51mypc.cn/&response_type=code&scope=snsapi_login&state=gd5q4tcr#wechat_redirect*/
/*
https://open.weixin.qq.com/connect/qrconnect
?appid=wx4530e35e8a9fc5cd
&redirect_uri=http://www.mogujie.com/oauth/callback/weixin/mogujie?referer_key=5E6BA887B6F2988918AD730D730D8D69&response_type=code&scope=snsapi_login
*/ 
export const jsReg = {
    number : /^[0-9]*$/,// /^\d+$/,
    email : /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,// /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/,
}


export const getUrlKey = function (name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

export function getJSSDK(url, dataForWeixin) {
    window.wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //下面这里不用管  让后台看一下  让他返给你 
        appId: 'wx8beb9047b24697e2', // 必填，公众号的唯一标识
        timestamp: '', // 必填，生成签名的时间戳
        nonceStr: '', // 必填，生成签名的随机串
        signature: '', // 必填，签名
        jsApiList: [
            'onMenuShareAppMessage',
            'onMenuShareQQ'
        ] // 必填，需要使用的JS接口列表
    });
    window.wx.ready(function () {
        window.wx.onMenuShareAppMessage({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) { },
            success: function success(res) {
                console.log('已分享');
            },
            cancel: function cancel(res) {
                console.log('已取消');
            },
            fail: function fail(res) {
                alert(JSON.stringify(res));
            }
        });
        // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
        window.wx.onMenuShareTimeline({
            title: dataForWeixin.title,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                // alert('用户点击分享到朋友圈');
            },
            success: function success(res) {
                //alert('已分享');
            },
            cancel: function cancel(res) {
                //alert('已取消');
            },
            fail: function fail(res) {
                alert(JSON.stringify(res));
            }
        });
        // 2.3 监听“分享到QQ”按钮点击、自定义分享内容及分享结果接口
        window.wx.onMenuShareQQ({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                //alert('用户点击分享到QQ');
            },
            complete: function complete(res) {
                alert(JSON.stringify(res));
            },
            success: function success(res) {
                //alert('已分享');
            },
            cancel: function cancel(res) {
                //alert('已取消');
            },
            fail: function fail(res) {
                //alert(JSON.stringify(res));
            }
        });
        // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
        window.wx.onMenuShareWeibo({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                alert('用户点击分享到微博');
            },
            complete: function complete(res) {
                alert(JSON.stringify(res));
            },
            success: function success(res) {
                alert('已分享');
            },
            cancel: function cancel(res) {
                alert('已取消');
            },
            fail: function fail(res) {
                alert(JSON.stringify(res));
            }
        });
    });
    window.wx.error(function (res) {
        alert("微信验证失败");
    });
}




/**
 * 判断是否为安卓机型
 */
 export function isAndroid() {
    let _ua = window.navigator.userAgent.toLocaleLowerCase()
    if (_ua.indexOf('android') > -1 || _ua.indexOf('adr') > -1) {
        return true
    } else {
        return false
    }
}
   
/**
 * 判断是否为ios机型
 */
export function isIos() {
    let _ua = window.navigator.userAgent
    if (!!_ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return true
    } else {
        return false
    }
}







/*
 * 校验表单字段
 * 方法blurRule、IdentityCodeValid结合使用
 * idno：身份证校验
 * name：中文名称校验
 * phone：手机号校验
 * email：邮箱校验
 * address：详细地址校验
 * postcode：邮编校验
 * ratio、weight、height：数字校验
 */ 
export const blurRule=(value, rule)=> {
    if (rule.name == "idno") {
        if(![15,18].includes(value.length)){
            ElementUI.Message({
                message:'身份证号长度必须为15位或18位',
                type:'error',
                center: true,
                offset:10,
            });
           return false
        }else if(value.length=== 15){
            ElementUI.Message({
                message:'身份证号长度为15位时，只能录入数字',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if(value.length=== 18
            && !Reg.allNum.test(value.substr(0,17))
            && (value.substr(17,1)!=='X' || !Reg.allNum.test(value.substr(17,1)))){
            ElementUI.Message({
                message:'身份证号长度为18位时，前17位只能录入数字，第18位允许数字或“X”',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if (!IdentityCodeValid(value)) {
            ElementUI.Message({
                message:'请输入正确的证件号码',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if (rule.name == "name") {
        let noDot = value.replace('·','')
        if(!value){
            ElementUI.Message({
                message:'姓名不能为空',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if(noDot.length<2){
            ElementUI.Message({
                message:'姓名至少包含2个中文汉字',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if(value.length>12){
            ElementUI.Message({
                message:'姓名最长为12个长度',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if (!Reg.nameReg.test(value)) {
            ElementUI.Message({
                message:'姓名只允许输入中文或中文·中文格式',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if (rule.name == "phone") {
        if(!value){
            ElementUI.Message({
                message:'手机号不能为空',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if(value.length<11){
            ElementUI.Message({
                message:'手机号长度必须为11位纯数字格式',
                type:'error',
                center: true,
                offset:10,
            });
           return false
        }else if (value.substr(0,1)!=='1'){
            ElementUI.Message({
                message:'手机号第一位固定为1',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if (!Reg.phoneRegs.test(value)) {
            ElementUI.Message({
                message:'请输入正确的手机号码',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if(rule.name == 'address'){
        if(!value){
            ElementUI.Message({
                message:'详细地址不能为空',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }else if (Reg.addressregEn.test(value)||Reg.addressregCn.test(value)) {
            ElementUI.Message({
                message:'请输入正确的详细地址',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if(rule.name == 'email'){
        if (!Reg.mailReg.test(value)) {
            ElementUI.Message({
                message:'请输入正确的邮箱号码',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if(rule.name == 'postcode'){
        let reg = /^[1-9]\d{6}/;
        if (!reg.test(value)) {
            ElementUI.Message({
                message:'请输入正确的邮编地址',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }else if (rule.name == 'ratio'){
        if (!value) {
            ElementUI.Message({
                message:'输入不可以为空',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
        setTimeout(() => {
            if (!Number(value)) {
                ElementUI.Message({
                    message:'请输入正整数',
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false
            } else {
                const re = /^[0-9]*[1-9][0-9]*$/;
                const rsCheck = re.test(value);
                if (!rsCheck) {
                    ElementUI.Message({
                        message:'请输入正整数',
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                } else {

                }
            }
        }, 0);
    }else if (rule.name == "weight" || rule.name == "height") {
        if (!Reg.weight_height.test(value)) {
            ElementUI.Message({
                message:'请输入数字',
                type:'error',
                center: true,
                offset:10,
            });
            return false
        }
    }
    return true
}

export const gettimedate=(data)=> {
    let value = data.getFullYear() + '-' +
        checkTime(data.getMonth() + 1) + '-' +
        checkTime(data.getDate()) + ' ' +
        checkTime(data.getHours()) + ':' +
        checkTime(data.getMinutes()) + ':' +
        checkTime(data.getSeconds());
    return value
}
export const checkTime=(i)=> {
    if (i < 10) {
        i = "0" + i
    }
    return i;
}

export const formatDate=(date, fmt)=> {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
      }
    }
    return fmt
}
   
export const padLeftZero=(str)=> {
    return ('00' + str).substr(str.length)
}

//
/**
 * 手机号码正则验证
 * @param value
 */
export function isPhone(value) {
    var reg = /^1[0-9]{10}$/;
    if (!reg.test(value)) {
        return false;
    } else {
        return true;
    }
}
/**
 * html5本地sessionStorage储存封装
 * @param key
 * @param value
 */
export const setSessionItem = function(key, value) {
    if (typeof(value)!='string') {
        value = JSON.stringify(value);
    }
    return window.sessionStorage.setItem(key, value);
}
/**
 * html5本地sessionStorage储存获取封装
 * @param key
 */
export const getSessionItem = function(key) {
    var string = window.sessionStorage.getItem(key);
    try {
        var obj=JSON.parse(string);
        if(typeof obj == 'object' && obj ){
            return obj;
        }else{
            if(string=='null') {
                return null;
            } else {
                return string;
            }					
        }
    } catch(e) {
        if(string=='null') {
            return null;
        } else {
            return string;
        }
    }
}
/**
 * html5本地sessionStorage删除储存
 * @param key
 */
export const delSessionItem = function(key) {
    window.sessionStorage.removeItem(key);
}
/**
 * html5本地localStorage储存封装
 * @param key
 * @param value
 */
export const setLocalItem = function(key, value) {
    if (typeof(value)!='string') {
        value = JSON.stringify(value);
    }
    return window.localStorage.setItem(key, value);
}
/**
 * html5本地localStorage储存获取封装
 * @param key
 */
export const getLocalItem = function(key) {
    var string = window.localStorage.getItem(key);
    try {
        var obj=JSON.parse(string);
        if(typeof obj == 'object' && obj ){
            return obj;
        }else{
            if(string=='null') {
                return null;
            } else {
                return string;
            }					
        }
    } catch(e) {
        if(string=='null') {
            return null;
        } else {
            return string;
        }
    }
}
/**
 * html5本地localStorage删除储存
 * @param key
 */
export function delLocalItem(key) {
    localStorage.removeItem(key);
}
export function thousandSplit (num) {
    return ("" + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
    
}
/**
 * 微信分享
 * @param key
 */


export const wxConfig = function (data, paramsObj) {
    wx.config({
        debug: false,
        appId: data.appid,
        timestamp: data.timestamp,
        nonceStr: data.noncestr,
        signature: data.signature,
        jsApiList: [
            'chooseImage',
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
            // 'updateAppMessageShareData',
            // 'updateTimelineShareData',
        ]
    });
    wx.ready(function () {
        wx.checkJsApi({
            jsApiList: [
                'chooseImage',
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                // 'updateAppMessageShareData',
                // 'updateTimelineShareData',
            ]
        })
        // var shareImg = 'https://zhitu.sozdata.com/assets/images/logo.png'
        var objParams = {
            title: paramsObj.title,
            desc: paramsObj.content,
            link: paramsObj.shareUrl,
            imgUrl: paramsObj.imgUrl,//shareImg,
            success: function (res) {
            },
            complete: function (res) {
            },
            cancel: function (res) {
            // 用户取消分享后执行的回调函数
            }
        }
        wx.onMenuShareAppMessage(objParams);
        wx.onMenuShareTimeline(objParams);
    })
    wx.error(function (res) {
        alert(res.errMsg);
    });
}