import zhLocale from "element-ui/lib/locale/lang/zh-CN";
const cn = {
    message:{
        "loading":"加载中",
        "header":"选择另一个国家或地区进行在线购物并查看特定于您所在位置的内容",
        "nav-head":"未成年人禁止购买使用",
        "nav":"吸烟有害健康",
        "navigazione":" 欧元起免运费",
        "navigazionemap":"查看附近的商店",
        "home":"首页",
        "shop":"商品",
        "special":"特别优惠",
        "aboutus":"关于我们",
        "support":"支持",
        "cooperation":"合作机会",
        "blog":"博客",
        "accountinfo":"账户信息",
        "myorder":"我的订单",
        "outlog":"退出登录",
        "cashop":"查看全部商品",
        "whearbay":"哪里可以买",
        "moreinfo":"更多信息",
        "nowshop":"现在去购物",
        "newweet":"新口味",
        "allcomment":"综合评价",
        "usstart":"我们的4星和5星评论",
        "linkus":"联系我们",
        "guardianplan":"监护人计划",
        "illicittrade":"非法贸易",
        "servecenter":"服务中心",
        "warrantybill":"保修单",
        "seeproblem":"常见问题",
        "shopfixed":"商店定位器",
        "dischild":"禁止向18岁以下的未成年出售",
        "discenten":"网站经国家垄断企业授权，用于在线销售电子烟用液体。",
        "disfoot":"税务仓库：FIPLI0008",
        "footnum":"条款条约",
        "footext":"隐私政策",
        "specialtxt1":"所有产品都在50%折扣销售!!!",
        "specialtxt2":"商品在售可能因保质期、零星库存、包装更新等原因。",
        "specialtxt3":"我们在销售中不换货或退款，请您谨慎下单。",
        "specialshop":"优惠商品",
        "color":"颜色",
        "num":"数量",
        "addcar":"加入购物车",
        "search":"搜索",
        "searchsee":"搜索常见问题",
        "searchend":"搜索结果",
        "cooperationtxt1":"感谢您联系37 Vape。收到您的申请后，我们将在3个工作日内与您联系。",
        "cooperationtxt2":"带有*号为必填项",
        "allname":"全名",
        "age":"年龄",
        "occupation":"职业",
        "industry":"行业",
        "webint":"公司网站",
        "status":"状态",
        "country":"国家",
        "firstarr1":"首选合作方式(可多选)",
        "firstarr2":"入驻类型(可多选)",
        "firstarr3":"请输入您的入驻类型",
        "firstarr4":"有多少零售商店/附属公司链接",
        "firstarr5":"您拥有零售店吗？(单选)",
        "firstarr6":"规模(单选)",
        "movephone":"移动电话",
        "emialphone":"联系电子邮件",
        "phoneinfo":"联系信息",
        "coeatxt1":"您会考虑投资或开设37 Vape 独家专卖店吗？",
        "wherecity":"哪个城市",
        "wherewhy":"为什么要与YOZITALY合作？",
        "wherename":"您是由 YOZITALY 员工推荐的吗？如果有，请提供他们的全名。",
        "wherecheck1":"订阅以接收新闻通讯/营销信息。",
        "wherecheck2":"我们非常重视您的隐私，只会使用您的个人信息来提供您要求的产品和服务。要继续，请阅读并同意37 Vape 的隐私政策和条款条件。",
        "shareto":"分享至",
        "shoptxt":"商品介绍",
        "commentxt":"评价",
        "ucom":"顾客评价",
        "recoment":"在这里输入你的评论",
        "priceshop":"单价",
        "tbmore":"操作",
        "haspost":"含运费",
        "loginfo":"物流信息",
        "delorder":"删除订单",
        "shoinfo":"申请售后",
        "orinfo":"订单详情",
        "orddetail":"订单信息",
        "shoshopinfo":"收货信息",
        "shoppeple":"收件人",
        "shoaddress":"收货地址",
        "ortime":"下单时间",
        "pytime":"付款时间",
        "ftime":"发货时间",
        "fintime":"完成时间",
        "orstatus":"订单状态",
        "nowpay":"立即支付",
        "shopmoney":"商品金额",
        "postmoney":"运费",
        "paymoeny":"实付款",
        "ermovepost":"扣除邮费",
        "tmoney":"退款总额",
        "logis":"物流",
        "logising":"运输中",
        "logisnum":"运单编号",
        "logcompay":"物流公司",
        "fpople":"发货人",
        "fadress":"发货地址",
        "tksuccess":"退款成功",
        "tkwhy":"退款原因",
        "tmoneys":"退款金额",
        "tkbz":"退款说明",
        "palytime":"申请时间",
        "thaddress":"退货地址",
        "quyinfo":"查看售后详情",
        "state0":"订单状态",
        "state1":"商品已拍下，等待买家付款",
        "state2":"买家已付款，等待商家发货",
        "state3":"商家已发货，等待买家确认",
        "state4":"交易成功",
        "state5":"已提交售后申请，等待商家处理",
        "state6":"订单关闭，退款成功",
        "state7":"退款成功",
        "state8":"请等待商家处理",
        "state9":"请按照给出的退货地址退货，退货时请在包裹中备注您的账号，以便于我们收到货后审核退款",
        "logintitle":"登录到您的账户",
        "regtitle":"创建您的新账户",
        "phonenum":"手机号",
        "emailnum":"电子邮箱",
        "passnum":"密码",
        "invitationnum":"邀请码",
        "othtype":"其他登录方式",
        "loginbtn":"登录",
        "othregtype":"其他注册方式",
        "regbtn":"注册",
        "newuser":"我是新用户",
        "fgetpass":"忘记密码",
        "ycode":"验证码",
        "getcode":"获取验证码",
        "hasacc":"已有账户",
        "emailfind":"电子邮箱找回",
        "phonefind":"手机号找回",
        "nowfind":"立即找回",
        "bindphone":"绑定手机号",
        "addinfo":"完善信息",
        "nowbind":"立即绑定",
        "name":"名",
        "surname":"姓",
        "country":"国籍",
        "firm":"确定",
        "email":"邮箱",
        "agetxt1":"请核实您的年龄",
        "agetxt2":"产品含有尼古丁，不适合未成年人。",
        "agetxt3":"请确认您的年龄以继续",
        "agetxt4":"我已经18+",
        "agetxt5":"我还不到18岁",
        "chioselang":"请选择语言",
        "wenkf":"问客服",
        "nameuser":"姓名",
        "mobilenum":"电话号",
        "areinfo":"问题描述",
        "submitbtn":"提交",
        "mycar":"我的购物车",
        "emptycar":"清空购物车",
        "allshop":"全部商品",
        "letpay":"立即购买",
        "todopay":"继续购买",
        "checkorderinfo":"填写并核对订单信息",
        "moenytotal":"小计",
        "postmoneys":"邮费",
        "fullpay":"满",
        "freepay":"包邮",
        "paymoenyed":"应付总额",
        "plan":"计划",
        "newaddress":"使用新地址",
        "conuycity":"国家/地区",
        "address":"地址",
        "doornum":"公寓、门牌号等",
        "doorling":"门铃",
        "city":"城市",
        "postcode":"邮政编码",
        "telep":"电话",
        "suborder":"提交订单",
        "update":"修改",
        "pay":"支付",
        "paytotal":"立即付款",
        "paysuccess":"支付成功",
        "quyorderinfo":"查看订单详情",
        "orinfotip":"本平台不会以任何理由要求您提供银行卡信息或支付额外费用,请谨防钓鱼链接或诈骗电话",
        "ordernum":"订单编号",
        "sshopinfo":"收货信息",
        "shopnames":"商品名称",
        "paytype":"支付方式",
        "myuser":"我的账户",
        "userinfo":"账号信息",
        "hi":"嗨",
        "addressfrom":"地址管理",
        "allmpney":"总金额",
        "postalcode":"邮编",
        "destinatario":"收货人",
        "order":"订单",
        "fhinfo":"发货信息",
        "shinfo":"售后详情",
        "shshop":"售后商品",
        "reg1":"我已阅读并同意37 Vape的",
        "and":"和",
        "usearicle":"使用条款",
        "province":"省份",
        "welcom":"欢迎来到37 Vape",
        "nodata":"暂无相关数据",
        "wchartpay":"微信支付",
        "zfbpay":"支付宝支付",
        "xykpay":"信用卡支付",
        "xxpay":"线下支付",
        "zxpay":"在线支付",
        "hdfkpay":"货到付款",
        "carshop":"购物车",
        "carnodata":"快去选购吧",
        "coderest":"后重新获取",
        "added":"新建&编辑",
        "addsub":"添加新地址/确定",
        "defaultcheck":"设为默认",
        "defaultaddress":"默认地址",
        "newaddress":"添加新地址",
        "edits":"编辑",
        "dels":"删除",
        "seemore":"查看更多",
        "shopcomment":"商品评分",
        "subcommont":"发布评价",
        "writehert":"写下购买体会和使用心得吧",
        "seelog":"查看物流",
        "comfiresh":"确认收货",
        "showstep":"售后进度",
        "all":"全部",
        "nolist":"暂无数据",
        "ruse":"已创建",
        "writpaylab":"待付款",
        "writfh":"待发货",
        "writshlab":"待收货",
        "successlab":"已完成",
        "showlab":"售后",
        "solding":"售后中",
        "writstate1":"货到付款，等待商家发货",
        "writstate2":"商家收货后会将您的退款金额返还至支付账号，请耐心等待",
        "subforms":"提交申请",
        "allcheck":"全选",
        "seeall":"查看全部",
        "findshop":"寻找商店",
        "homepro1":"您的下一代汽化器和电子烟在线商店",
        "homepro2":"RELX POD的棕榈园、香果、薄荷醇等",
        "homepro3":"RELX是您购买高级电子烟和下一代电子烟设备的在线资源。我们使用最现代的生产技术，为您提供创新设备。我们的产品将时尚的现代设计与尖端技术相结合，为您提供最好的在线电子烟和电子烟笔。",
        "promore":"产品系列",
        "shoplist":"门店列表",
        "shopfixed":"店铺定位器",
        "langage":"语言",
        "linktype":"联系方式",
        "comments":"评论",
        "share":"分享",
        "uspromise":"我们的承诺",
        "usoutjoin":"我们的外部参与",
        "usoutjoins":"我们的外部参与",
        "zzauto":"资质认证",
        "usebook":"用户手册",
        "shoptype":"商品分类",
        "usernametip":"请输入您的姓名",
        "emailtip":"请输入您的邮箱",
        "teletip":"请输入您的电话号",
        "problemtip":"请输入您的问题描述",
        "passwordtip":"请输入您的密码",
        "phonetip":"请输入您的手机号",
        "phonecodetip":"请输入您的短信验证码",
        "seechecktip":"请您阅读并同意37 Vape的隐私政策和使用条款",
        "allnametip":"请输入您的全名",
        "agetip":"请输入您的年龄",
        "occupationtip":"请输入您的职业",
        "industrytip":"请输入您的行业",
        "citytip":"请输入您的城市",
        "statustip":"请输入您的状态",
        "countrytip":"请输入您的国家",
        "cooptypetip":"请选择首选合作方式",
        "jiontypetip":"请选择入驻类型",
        "companytip":"请输入您有几家零售商店/附属公司链接",
        "shoptip":"请选择您拥有零售店吗",
        "gmtip":"请选择规模",
        "mobiletip":"请输入移动电话",
        "eemailtip":"请输入联系电子邮箱",
        "oneshoptip":"请选择您会考虑投资或开设 RELX 独家专卖店吗",
        "checkinfotip":"请您同意订阅以接收新闻通讯/营销信息",
        "checkwheretip":"请您阅读并同意RELX的隐私政策和条款条件",
        "numbertip":"数量低于范围",
        "carsuccesstip":"加入购物车成功",
        "logintip":"请先登录",
        "areatip":"请选择国家/地区",
        "provincetip":"请输入省份",
        "nametip":"请输入您的名",
        "lasnametip":"请输入您的姓",
        "addresstip":"请输入您的地址",
        "apartmenttip":"请输入公寓、门牌号等",
        "doortip":"请输入门铃",
        "postcodetip":"请输入邮编 ",
        "telepstip":"请输入您的电话",
        "updatetip":"修改地址成功",
        "tkshoptip":"请选择退款商品",
        "searontip":"请选择退款原因",
        "tkdetailtip":"请输入退款说明",
        "prev":"上一页",
        "next":"下一页",
        "delsuccess":"删除成功",
        "wcharttitle":"请用微信扫一扫",
        "waptitle":"显示订单摘要",
        "comfirdel":"确定要删除吗？",
        "tiptitle":"提示",
        "removenumber":"数量不能再少了",
        "emptydel":" 确定要清空购物车产品吗？",
        "ycan":"您可以",
        "m":"€",
        "mpay":"",//欧元
        "showstate1":"待商家审核",
        "showstate2":"待买家退货",
        "showstate3":"待商家收货",
        "showstate4":"待商家打款",
        "showstate5":"售后完成",
        "showstate6":"售后拒绝",
        "showstate7":"售后关闭",
        "hide":"收起",
        "companyname":"企业名字",
        "companytitle":"企业名称",
        "companynum":"企业税号",
        "companyaddress":"企业地址",
        "companymobile":"联系电话",
        "companyenum":"电子发票号码",
        "shouxufei":"(€",
        "shouxufei2":" 手续费)",
    },
    ...zhLocale
}
export default cn;