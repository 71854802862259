
import {getDictionaryAll} from "@/api/index.js";
const state = {
  loginNameStatus:{},
  languageNameStatus:{},
  languageNameList:[],
  loginStatus:false,
  helpStatus:false,
  tipleStatus:false,
  delStatus:false,
  delItemStatus:{},
  InsureDic:[],
}
const getters = {
  getLoginNameStatus(state){
    return state.loginNameStatus
  },
  getLanguageNameStatus(state){
    return state.languageNameStatus
  },
  getLanguageNameList(state){
    return state.languageNameList
  },
  getLoginStatus(state){
    return state.loginStatus
  },
  getHelpStatus(state){
    return state.helpStatus
  },
  getTipleStatus(state){
    return state.tipleStatus
  },
  getDelStatus(state){
    return state.delStatus
  },
  getDelItemStatus(state){
    return state.delItemStatus
  },
  InsureDic:state => state.InsureDic,
}
const mutations = {
  setLoginNameStatus(state,data){
    state.loginNameStatus = data;
  },
  setLanguageNameStatus(state,data){
    state.languageNameStatus = data;
  },
  setLanguageNameList(state,data){
    state.languageNameList = data;
  },
  setLoginStatus(state,data){
    state.loginStatus = data;
  },
  setHelpStatus(state,data){
    state.helpStatus = data;
  },
  setTipleStatus(state,data){
    state.tipleStatus = data;
  },
  setDelStatus(state,data){
    state.delStatus = data;
  },
  setDelItemStatus(state,data){
    state.delItemStatus = data;
  },
  setInsureDic(state,data){
    state.InsureDic= data;
  }
}
const actions = {
  setLoginNameStatus(context,data){
    context.commit('setLoginNameStatus',data);
  },
  setLanguageNameStatus(context,data){
    context.commit('setLanguageNameStatus',data);
  },
  setLanguageNameList(context,data){
    context.commit('setLanguageNameList',data);
  },
  setLoginStatus(context,data){
    context.commit('setLoginStatus',data);
  },
  setHelpStatus(context,data){
    context.commit('setHelpStatus',data);
  },
  setTipleStatus(context,data){
    context.commit('setTipleStatus',data);
  },
  setDelStatus(context,data){
    context.commit('setDelStatus',data);
  },
  setDelItemStatus(context,data){
    context.commit('setDelItemStatus',data);
  },
  async getInsureDic({commit}, data){
    try {
      let res = await getDictionaryAll(data);
      if(res.code === 1){
        commit('setInsureDic',res.data)
      }
    }catch (e) {
        console.log(e)
    }
  },
}
export default {
    state,
    getters,
    actions,
    mutations
}
