const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const index = () => import(/* webpackChunkName: "index" */ '@/pages/index/index');//首页
const home = () => import(/* webpackChunkName: "home" */ '@/pages/index/home');//特别优惠
const article = () => import(/* webpackChunkName: "article" */ '@/pages/index/article');//35元起免运费文章
const list = () => import(/* webpackChunkName: "list" */ '@/pages/index/list');//商品分类列表
const info = () => import(/* webpackChunkName: "info" */ '@/pages/index/info');//商品详情
const map = () => import(/* webpackChunkName: "map" */ '@/pages/index/map');//附近商店地图
const googleoauth = () => import(/* webpackChunkName: "googleoauth" */ '@/components/commons/googleoauth');//google授权
const mapgoogle = () => import(/* webpackChunkName: "mapgoogle" */ '@/components/other/mapgoogle');//pc地图
const mapgooglewap = () => import(/* webpackChunkName: "mapgooglewap" */ '@/components/other/mapgooglewap');//wap地图
const wchartoauth = () => import(/* webpackChunkName: "wchartoauth" */ '@/components/commons/wchartoauth');//wchart
const wchartlogin = () => import(/* webpackChunkName: "wchartlogin" */ '@/components/commons/wchartlogin');//wchart

export default [
    {
        path: '/',
        name: 'index',
        meta: {
            keepAlive: true,
        },
        components: {
            default: index,
            header:header,
            footer:footer,
        }
    },
    // {
    //     path: '/index',
    //     name: 'index',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: index,
    //         header:header,
    //         footer:footer,
    //     }
    // },
    {
        path: '/home',
        name: 'home',
        meta: {
            keepAlive: true,
        },
        components: {
            default: home,
            header:header,
            footer:footer,
        }
    },{
        path: '/article',
        name: 'article',
        meta: {
            keepAlive: true,
        },
        components: {
            default: article,
            header:header,
            footer:footer,
        }
    },{
        path: '/list',
        name: 'list',
        meta: {
            keepAlive: true,
        },
        components: {
            default: list,
            header:header,
            footer:footer,
        }
    },{
        path: '/info',
        name: 'info',
        meta: {
            keepAlive: true,
        },
        components: {
            default: info,
            header:header,
            footer:footer,
        }
    },{
        path: '/map',
        name: 'map',
        meta: {
            keepAlive: true,
        },
        components: {
            default: map,
            // header:header,
            // footer:footer,
        }
    },
    {
        path: '/googleoauth',
        name: 'googleoauth',
        meta: {
            keepAlive: true,
        },
        components: {
            default: googleoauth,
        }
    },{
        
        path: '/mapgooglewap',
        name: 'mapgooglewap',
        meta: {
            keepAlive: true,
        },
        components: {
            default: mapgooglewap,
        }
    },{
        
        path: '/wchartoauth',
        name: 'wchartoauth',
        meta: {
            keepAlive: true,
        },
        components: {
            default: wchartoauth,
        }
    },{
        
        path: '/wchartlogin',
        name: 'wchartlogin',
        meta: {
            keepAlive: true,
        },
        components: {
            default: wchartlogin,
        }
    }
];
