//中英文切换
import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import messages from './langs'
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale:localStorage.lang||'it',   //从localStorage里获取用户选择的语言，没有则默认意大利语
    messages
});
locale.i18n((key,value)=>i18n.t(key,value));
export default i18n;