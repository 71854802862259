import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './home';//首页+商品详情+特别优惠
import shopcar from './shopcar';//购物车
import users from './users';//个人中心
import support from './support';//支持
import cooperation from './cooperation';//合作机会
import blog from './blog';//博客
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',//'hash',//
  base: process.env.BASE_URL,
  routes:[
    ...home,
    ...shopcar,
    ...support,
    ...cooperation,
    ...users,
    ...blog,
  ]
});
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;
