import axios from 'axios'
import store from '@/store'
import {service} from './api.js';
import {urlPrefix} from './common.js';
import ElementUI from 'element-ui';
import {getLocalItem,yooz_langage} from '@/untils/common';
import loading from '@/components/loading'
let instance = axios.create({
    baseURL: "",//"http://yoozi.txunda.com:81/index.php/",
    timeout:600000,
    withCredentials: true,
});
export const Http = {}; // 包裹请求方法的容器
// 请求格式/参数的统一
for(let key in service){
    let api = service[key]; // url method
    // async 作用：避免进入回调地狱
    Http[key] = async function(
        params, // 请求参数 get：url，put，post，patch（data），delete：url
        isFormData=false,// 标识是否是form-data请求
        configParmas={} // 配置参数
    ){
        let newParams = {}
        // let typeId = '9004';//设置全局参数typeId
        //  content-type是否是form-data的判断
        if(params && isFormData){
            newParams = new FormData()
            for(let i in params){
                newParams.append(i,params[i])
            }
        }else{
            newParams = params
        }
        // if(newParams) {
        //     newParams.typeId = typeId;
        // } else {
        //     newParams = {
        //         typeId:typeId
        //     }
        // }
        // console.log(newParams)
        // 不同请求的判断
        let response = {}; // 请求的返回值
        if(api.method === 'post'){
            try{                
                if(api.noTrans) {
                    var postParams = new URLSearchParams();
                    for(let i in newParams) {
                        postParams.append(i,newParams[i]);
                    }
                    response =  await instance[api.method](urlPrefix[api.site]+api.url,postParams,configParmas)
                } else {
                    response =  await instance[api.method](urlPrefix[api.site]+api.url,newParams,configParmas)
                }
            }catch(err){
                response = err
            }
        }else if(api.method === 'delete'|| api.method === 'get'){
            configParmas.params = newParams
            try{
                if(api.noTrans) {
                    var postParams = new URLSearchParams();
                    for(let i in newParams) {
                        postParams.append(i,newParams[i]);
                        
                    }
                    response =  await instance[api.method](urlPrefix[api.site]+api.url,configParmas)
                } else {
                    response =  await instance[api.method](urlPrefix[api.site]+api.url,configParmas)
                }
            }catch(err){
                response = err
            }
        }
        return response; // 返回响应值
    }
}
instance.interceptors.request.use(config=>{
    if(localStorage.lang=='en'){
        loading.showLoading({title:'Loading'})
    }else if(localStorage.lang=='cn'){
        loading.showLoading({title:'加载中'})
    }else {
        loading.showLoading({title:'Caricamento'})
    } 
    let storelang = store.state.data.languageNameStatus.lang;
    let lang = storelang?storelang:(getLocalItem('langcode')?getLocalItem('langcode'):yooz_langage);
    config.headers['accept-language'] = lang;
    // 发起请求前做些什么
    return config
},(error)=>{
    return Promise.reject(error);
    // 请求错误
    // console.log('请求错误!');
})
// 响应拦截器
instance.interceptors.response.use(response=>{
    loading.cancelLoading()
    // 请求成功
    return response.data;
},(error)=>{
    let tips;
    if(error&&error.response) {
        switch(error.response.status){
            case 400:
                    tips = '错误请求-'+error.response.status;
                break;
            case 401:
                    tips = '未授权，请重新登录-'+error.response.status;
                break;
            case 403:
                    tips = '拒绝访问-'+error.response.status;
                break;
            case 404:
                    tips = '错误请求，未找到该资源-'+error.response.status;
                break;
            case 405:
                    tips = '请求方法未允许-'+error.response.status;
                break;
            case 408:
                    tips = '请求超时-'+error.response.status;
                break;
            case 500:
                    tips = '服务器端出错-'+error.response.status;
                break;
            case 501:
                    tips = '网络未实现-'+error.response.status;
                break;
            case 502:
                    tips = '网络错误-'+error.response.status;
                break;
            case 503:
                    tips = '服务不可用-'+error.response.status;
                break;
            case 504:
                    tips = '网络超时-'+error.response.status;
                break;
            case 505:
                    tips = 'http版本不支持该请求-'+error.response.status;
                break;
            default:
                tips = `连接错误${error.response.status}`;
                break;
        }
    } else{
        tips = '网络出现问题，请稍候重试';
    }
    // ElementUI.Message({
    //     message:tips,
    //     type:'error',
    //     center: true,
    //     offset:10,
    // });
})
