import itLocale from "element-ui/lib/locale/lang/zh-TW";
const it = {
    message:{
        "loading":"Caricamento",
        "header":"Seleziona la tua lingua",
        "nav-head":"Verifica la tua eta'. Vietato la vendita e rivendita ai minori",
        "nav":"I prodotti 37 Vape contengono nicotina e non sono adatti ai minori.",
        "navigazione":"Spedizione gratuita a partire da €",
        "navigazionemap":"Negozi vicini",
        "home":"Home",
        "shop":"Acquista",
        "special":"Offerte Speciali",
        "aboutus":"Chi siamo",
        "support":"Supporto",
        "cooperation":"Collaborazione",
        "blog":"Seguici sui social",
        "accountinfo":"Profilo",
        "myorder":"I miei ordini",
        "outlog":"Esci e accedi",
        "cashop":"Visualizza tutti i prodotti",
        "whearbay":"Dove posso comprarlo",
        "moreinfo":"Piu' informazioni",
        "nowshop":"Acquista ora",
        "newweet":"Nuovi gusti",
        "allcomment":"Recensioni",
        "usstart":"Le nostre recensioni a 4 e 5 stelle",
        "linkus":"I nostri social",
        "guardianplan":"Programma guardian",
        "illicittrade":"Commercio illecito",
        "servecenter":"Centro di supporto",
        "warrantybill":"Garanzia",
        "seeproblem":"Domande frequenti",
        "shopfixed":"Negozi vicini",
        "dischild":"Vietata la vendita ai minori",
        "discenten":"Il sito è autorizzato dal monopolio dello stato alla vendita online di liquidi per sigarette elettroniche.",
        "disfoot":"PIVA: 11632470966",
        "footnum":"Termini e condizioni",
        "footext":"Privacy",
        "specialtxt1":"Sconto 50% su tutti i prodotti",
        "specialtxt2":"Le merci possono essere vendute a causa della durata di conservazione, scorte sporadiche, aggiornamenti degli imballaggi e altri motivi.",
        "specialtxt3":"Non effettuiamo cambi o rimborsi, si prega di effettuare l'ordine con attenzione.",
        "specialshop":"Saldi",
        "color":"Colori",
        "num":"Quantita'",
        "addcar":"Aggiungi al carello",
        "search":"Cerca",
        "searchsee":"Cerca domande frequenti",
        "searchend":"Risultati",
        "cooperationtxt1":"Grazie per aver contattato 37 Vape. Vi risponderemo entro 3 gionri lavorativi",
        "cooperationtxt2":"I campi contrassegnati con * sono obbligatori",
        "allname":"Nome e cognome",
        "age":"Eta'",
        "occupation":"Professione",
        "industry":"Settore",
        "webint":"Sito internet",
        "status":"Stato",
        "country":"Paese",
        "firstarr1":"Seleziona il modo di collaborazione (Scelte multiple)",
        "firstarr2":"Tipo di immissione (scelte multiple)",
        "firstarr3":"tipo di occupazione",
        "firstarr4":"Numero punti vendita/affiliare",
        "firstarr5":"Negozio retail？",
        "firstarr6":"Struttura del business",
        "movephone":"Numero di telefono",
        "emialphone":"Email",
        "phoneinfo":"Informazioni",
        "coeatxt1":"Vorresti investire o aprire un punto vendita esclusivo 37 Vape?",
        "wherecity":"citta'",
        "wherewhy":"Perche' scelgiere 37 Vape",
        "wherename":"Sei consigliato da un dipendente 37 Vape? In tal caso, fornire i loro nomi completi.",
        "wherecheck1":"Iscriviti per ricevere newsletter/informazioni marketing.",
        "wherecheck2":"Prendiamo molto sul serio la privacy dei nostri clienti e partners e utilizzeremo le informazioni personali solo per fornire i prodotti e i servizi richiesti. Per continuare, leggi e accetta la Privacy Policy e i Termini e Condizioni di 37 Vape.",
        "shareto":"Condividi",
        "shoptxt":"Descrizione",
        "commentxt":"Recensioni",
        "ucom":"Recensioni dei clienti",
        "recoment":"Aggiungi la tua recensione",
        "priceshop":"Prezzo",
        "tbmore":"Operazione",
        "haspost":"spese di pedizione comprese",
        "loginfo":"Informazioni sul tuo pacco",
        "delorder":"Cancella ordine",
        "shoinfo":"assitenza post-vendita",
        "orinfo":"Dettagli dell'ordine",
        "orddetail":"informazioni dell'ordine",
        "shoshopinfo":"Informazioni sulla consegna",
        "shoppeple":"destinatario",
        "shoaddress":"Indirizzo destinatario",
        "ortime":"Data dell'ordine",
        "pytime":"Data del pagamento",
        "ftime":"Data consegna",
        "fintime":"data della completazione",
        "orstatus":"Stato",
        "nowpay":"paga ora",
        "shopmoney":"Totale",
        "postmoney":"Costo di consegna",
        "paymoeny":"Totale",
        "ermovepost":"meno spese di spedizione",
        "tmoney":"totale rimborsato",
        "logis":"Logistica",
        "logising":"in transito",
        "logisnum":"Numero spedizione",
        "logcompay":"Compagnia",
        "fpople":"Mittente",
        "fadress":"Indirizzo di consegna",
        "tksuccess":"Conferma rimborso",
        "tkwhy":"Motivo del rimborso",
        "tmoneys":"Totale rimborsato",
        "tkbz":"Termini e condizioni del rimborso",
        "palytime":"Tempo di applications",
        "thaddress":"Indirizzo di ritorno",
        "quyinfo":"Visualizza dettagli",
        "state0":"Stato dell'ordine",
        "state1":"il prodotto è stato ordinato, in attesa del pagamento da parte dell'acquirente",
        "state2":"l'acquirente ha pagato, in attesa della spedizione del commerciante",
        "state3":"il commerciante ha spedito, in attesa della conferma dell'acquirente",
        "state4":"transazione riuscita",
        "state5":"l'applicazione è stata inviata, in attesa dell'elaborazione da parte del commerciante",
        "state6":"ordine chiuso, rimborso andato a buon fine",
        "state7":"Rimborso con successo",
        "state8":"Attendi l'elaborazione da parte del commerciante",
        "state9":"Si prega di restituire la merce in base all'indirizzo di restituzione fornito. Si prega di fornire il numero di conto nel pacco quando si restituisce la merce che verra' controllata.",
        "logintitle":"Accedi al tuo account",
        "regtitle":"Crea un nuovo account",
        "phonenum":"Numero di telefono",
        "emailnum":"E-mail",
        "passnum":"password",
        "invitationnum":"Codice invito",
        "othtype":"Altri metodi di accesso",
        "loginbtn":"Accesso",
        "othregtype":"Altri metodi di registrazione",
        "regbtn":"Registrati",
        "newuser":"Sono un nuovo utente",
        "fgetpass":"Ho dimenticato la password",
        "ycode":"codice di verifica",
        "getcode":"ottieni il codice di verifica",
        "hasacc":"Account gia esistente",
        "emailfind":"Recupero e-mail",
        "phonefind":"recupero del numero di telefono",
        "nowfind":"Recupera ora",
        "bindphone":"Associa numero di cellulare",
        "addinfo":"Informazioni perfette",
        "nowbind":"Accedi ora",
        "name":"nome",
        "surname":"cognome",
        "country":"Paese di cittadinanza",
        "firm":"conferma",
        "email":"email",
        "agetxt1":"Si prega di verificare la propria età",
        "agetxt2":"I prodotti contengono nicotina e non sono adatti ai minori.",
        "agetxt3":"Conferma la tua età per continuare",
        "agetxt4":"Ho 18 anni",
        "agetxt5":"Non ho ancora 18 anni",
        "chioselang":"selezionare la lingua",
        "wenkf":"chiedi al servizio clienti",
        "nameuser":"Nome",
        "mobilenum":"numero di telefono",
        "areinfo":"Descrizione del problema",
        "submitbtn":"Invia",
        "freepay":"spedizione gratuita",
        "fullpay":"Completo",
        "postmoneys":"Costo di consegna",
        "moenytotal":"totale",
        "checkorderinfo":"Compila e controlla le informazioni sull'ordine",
        "todopay":"continua a comprare",
        "letpay":"Acquista ora",
        "allshop":"tutti i prodotti",
        "emptycar":"carrello vuoto",
        "mycar":"il mio carrello",
        "paymoenyed":"Totale pagabile",
        "plan":"Programma 37 Vape",
        "newaddress":"usa il nuovo indirizzo",
        "conuycity":"paese/regione",
        "address":"indirizzo",
        "doornum":"Appartamento, numero civico, ecc.",
        "doorling":"campanello",
        "city":"Città",
        "postcode":"Cap",
        "telep":"Telefono",
        "suborder":"Inviare ordine",
        "update":"Controlla",
        "pay":"Paga",
        "paytotal":"paga ora",
        "paysuccess":"pagamento andato a buon fine",
        "quyorderinfo":"Visualizza i dettagli dell'ordine",
        "orinfotip":"Questa piattaforma non ti chiederà di fornire i dati della carta bancaria o di pagare commissioni aggiuntive per nessun motivo, fai attenzione ai link di phishing o alle chiamate fraudolente",
        "ordernum":"numero d'ordine",
        "sshopinfo":"Informazioni di consegna",
        "shopnames":"nome del prodotto",
        "paytype":"Metodo di pagamento",
        "myuser":"Il mio conto",
        "userinfo":"informazioni sull'account",
        "hi":"hi",
        "addressfrom":"Gestione indirizzi",
        "allmpney":"Importo totale",
        "postalcode":"codice postale",
        "destinatario":"destinatario",
        "order":"ordine",
        "fhinfo":"Informazioni sulla spedizione",
        "shinfo":"Dettagli post vendita",
        "shshop":"Prodotti post vendita",
        "reg1":"Ho letto e accetto l'Informativa",
        "and":"e",
        "usearicle":"le Condizioni d'uso",
        "province":"provincia",
        "welcom":"37 Vape ti da il benvenuto",
        "nodata":"Nessun dato rilevante",
        "wchartpay":"paga con wechat",
        "zfbpay":"paga con AliPay",
        "xykpay":"Paga con la carta di credito",
        "xxpay":"Paga con contanti",
        "zxpay":"Pagamento online",
        "hdfkpay":"pagamento alla consegna",
        "carshop":"carrello della spesa",
        "carnodata":"Compra ora",
        "coderest":"Recupera più tardi",
        "added":"Nuovo e modifica",
        "addsub":"Aggiungi nuovo indirizzo / OK",
        "defaultcheck":"imposta come predefinito",
        "defaultaddress":"Indirizzo predefinito",
        "newaddress":"Aggiungi un nuovo indirizzo",
        "edits":"modificare",
        "dels":"Elimina",
        "seemore":"Vedi altro",
        "shopcomment":"Valutazione del prodotto",
        "subcommont":"Pubblica una recensione",
        "writehert":"Com'e' andata la tua esperienza di acquisto e di utilizzo?",
        "seelog":"Visualizza dettagli",
        "comfiresh":"confermare il ricevimento della merce",
        "showstep":"Work progress",
        "all":"tutto",
        "nolist":"Nessun dato",
        "ruse":"creato",
        "writpaylab":"In attesa di Pagamento",
        "writfh":"da consegnare",
        "writshlab":"in attesa di ricezione",
        "successlab":"completato",
        "showlab":"Venduto",
        "solding":"Attendere grazie",
        "writstate1":"Pagamento in contrassegno, in attesa della spedizione del commerciante",
        "writstate2":"Dopo aver ricevuto la merce, l'importo del rimborso verrà restituito sul conto di pagamento, si prega di attendere",
        "subforms":"Conferma",
        "allcheck":"seleziona tutto",
        "seeall":"mostra tutto",
        "findshop":"Trova un negozio",
        "homepro1":"Il tuo negozio online di vaporizzatori e sigarette elettroniche della nuova generazione",
        "homepro2":"Giardino di palme, frutta profumata, mentolo e altro di 37 Vape PODs",
        "homepro3":"37 Vape è la tua risorsa online per l'acquisto di apparecchiature per lo svapo premium. Utilizziamo la più moderna tecnologia di produzione e vi offriamo attrezzature innovative. I nostri prodotti sono la creazione di un design elegante e moderno per offrirti le esperienze",
        "promore":"Serie di prodotti",
        "shoplist":"Elenco dei negozi",
        "shopfixed":"Store locator",
        "langage":"Lingua",
        "linktype":"Contatto",
        "comments":"Commento",
        "share":"Condividi",
        "uspromise":"II nostro impegno",
        "usoutjoin":"La nostra storia",
        "usoutjoins":"La nostra storia",
        "zzauto":"Certificazione",
        "usebook":"Manuale d'uso",
        "shoptype":"Prodotti 37 Vape",
        "usernametip":"inserisci il tuo nome",
        "emailtip":"inserisci la tua email",
        "teletip":"inserisci il tuo numero di telefono",
        "problemtip":"description	Descrizione del problema",
        "passwordtip":"inserisci la password",
        "phonetip":"inserisci il tuo numero di cellulare",
        "phonecodetip":"Inserisci il tuo codice di verifica SMS",
        "seechecktip":"Si prega di leggere e accettare l'Informativa sulla privacy e le Condizioni d'uso di 37 Vape",
        "allnametip":"Inserisci il tuo nome e cognome",
        "agetip":"inserisci la tua età",
        "occupationtip":"Inserisci la tua occupazione",
        "industrytip":"Inserisci il tuo settore",
        "citytip":"inserisci la tua città",
        "statustip":"inserisci il tuo stato",
        "countrytip":"inserisci il tuo paese",
        "cooptypetip":"seleziona il metodo di cooperazione preferito",
        "jiontypetip":"Inserisci la tua occupazione",
        "companytip":"Numero punti vendita/affiliare",
        "shoptip":"Seleziona se possiedi un negozio al dettaglio",
        "gmtip":"seleziona una metodo di collaborazione",
        "mobiletip":"inserisci il cellulare",
        "eemailtip":"inserisci l'e-mail di contatto",
        "oneshoptip":"Seleziona se intendi investire o aprire un negozio esclusivo 37 Vape",
        "checkinfotip":"iscriviti per ricevere newsletter/informazioni di marketing",
        "checkwheretip":"Si prega di leggere e accettare l'Informativa sulla privacy e i Termini e condizioni di 37 Vape",
        "numbertip":"Non e' abbastanza!",
        "carsuccesstip":"Aggiunto al carrello ",
        "logintip":"fai prima il log in",
        "areatip":"seleziona un paese",
        "provincetip":"inserirsci la provincia",
        "nametip":"inserisci il tuo nome",
        "lasnametip":"inserisci il tuo cognome",
        "addresstip":"inserisci il tuo indirizzo",
        "apartmenttip":"inserisci l'appartamento, il numero civico, ecc.",
        "doortip":"inserisci il campanello",
        "postcodetip":"inserisci il codice postale",
        "telepstip":"inserisci il tuo telefono",
        "updatetip":"Modifica indirizzo andato a buon fine",
        "tkshoptip":"Seleziona un articolo per il rimborso",
        "searontip":"Seleziona un motivo per il rimborso",
        "tkdetailtip":"Inserisci il metodo di rimborso per il rimborso",
        "prev":"pagina successiva",
        "next":"pagina precedente",
        "delsuccess":"Eliminato con successo",
        "wcharttitle":"Usa wechat per scansionare",
        "waptitle":"Mostra riepilogo dell'ordine",
        "comfirdel":"Sei sicuro di voler eliminare?",
        "tiptitle":"Consigli",
        "removenumber":"La quantità non può essere inferiore",
        "emptydel":" Sei sicuro di voler svuotare i prodotti del carrello?",
        "ycan":"Si può",
        "m":"€",
        "mpay":"euro",
        "showstate1":"Da rivedere dal commerciante",
        "showstate2":"Da restituire dall'acquirente",
        "showstate3":"Da ricevere dai commercianti",
        "showstate4":"Da pagare dal commerciante",
        "showstate5":"Dopo il completamento delle vendite",
        "showstate6":"Dopo il rifiuto delle vendite",
        "showstate7":"Chiusura post vendita",
        "hide":"Metti via",
        "companyname":"Nome dell' impresa",
        "companytitle":"Nome dell' impresa",
        "companynum":"Numero di imposta sulle impres",
        "companyaddress":"Indirizzo aziendale",
        "companymobile":"numero di contatto",
        "companyenum":"Numero di fattura elettronica",
        "shouxufei":"(€",
        "shouxufei2":" commissione)",
    },
    ...itLocale
}
export default it;