const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const shopcar = () => import(/* webpackChunkName: "shopcar" */ '@/pages/shopcar/shopcar');//购物车页面
const shopfrom = () => import(/* webpackChunkName: "shopfrom" */ '@/pages/shopcar/shopfrom');//填写并核对订单信息和支付
const paysuccess = () => import(/* webpackChunkName: "paysuccess" */ '@/pages/shopcar/paysuccess');//支付成功
const success = () => import(/* webpackChunkName: "paysuccess" */ '@/pages/shopcar/paysuccess');//支付成功
// const shopcarinfo = () => import('@/pages/shopcar/shopcarinfo');//填写并核对订单信息(暂时没有用)
// const shopcarpay = () => import('@/pages/shopcar/shopcarpay');//支付(暂时没有用)
export default [
    {
        path: '/shopcar',
        name: 'shopcar',
        meta: {
            keepAlive: true,
        },
        components: {
            default: shopcar,
            header:header,
            footer:footer,
        }
    },{
        path: '/shopfrom',
        name: 'shopfrom',
        meta: {
            keepAlive: true,
        },
        components: {
            default: shopfrom,
            header:header,
            footer:footer,
        }
    },
    // {
    //     path: '/shopcarinfo',
    //     name: 'shopcarinfo',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: shopcarinfo,
    //         header:header,
    //         footer:footer,
    //     }
    // },{
    //     path: '/shopcarpay',
    //     name: 'shopcarpay',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: shopcarpay,
    //         header:header,
    //         footer:footer,
    //     }
    // },
    {
        path: '/paysuccess',
        name: 'paysuccess',
        meta: {
            keepAlive: true,
        },
        components: {
            default: paysuccess,
            header:header,
            footer:footer,
        }
    },{
        path: '/success',
        name: 'success',
        meta: {
            keepAlive: true,
        },
        components: {
            default: success,
            header:header,
            footer:footer,
        }
    }
];
