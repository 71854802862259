import Vue from 'vue';
import {formatDate} from './common.js'
// 超出62字显示省略号
Vue.filter('ellipsis', (value) => {
    if (!value) return ''
    if (value.length > 62) {
        return value.slice(0,62) + '...'
    }
    return value
});
// 时间戳转换年月日格式'yyyy/MM/dd'
Vue.filter('formatDate', (time) => {
    time = time * 1000
    let date = new Date(time)
    return formatDate(date, 'yyyy/MM/dd')
});
