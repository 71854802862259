const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const problem = () => import(/* webpackChunkName: "problem" */ '@/pages/support/problem');//常见问题
// const linkus = () => import('@/pages/support/linkus');//联系我们(暂时没有用)
// const qualifications = () => import('@/pages/support/qualifications');//资质认证(暂时没有用)
// const repair = () => import('@/pages/support/repair');//保修单(暂时没有用)
// const usermanual = () => import('@/pages/support/usermanual');//用户手册(暂时没有用)
export default [
    {
        path: '/problem',
        name: 'problem',
        meta: {
            keepAlive: true,
        },
        components: {
            default: problem,
            header:header,
            footer:footer,
        }
    },
    // {
    //     path: '/linkus',
    //     name: 'linkus',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: linkus,
    //         header:header,
    //         footer:footer,
    //     }
    // },{
    //     path: '/qualifications',
    //     name: 'qualifications',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: qualifications,
    //         header:header,
    //         footer:footer,
    //     }
    // },{
    //     path: '/repair',
    //     name: 'repair',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: repair,
    //         header:header,
    //         footer:footer,
    //     }
    // },{
    //     path: '/usermanual',
    //     name: 'usermanual',
    //     meta: {
    //         keepAlive: true,
    //     },
    //     components: {
    //         default: usermanual,
    //         header:header,
    //         footer:footer,
    //     }
    // }
];
