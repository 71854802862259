<template>
    <b-modal size="sm" centered v-model="content" header-class="justify-content-center" hide-footer :title="showFlag==4||showFlag==3?$t('message.regtitle'):$t('message.logintitle')" @hidden="getClose">
        <div class="login-group" v-show="showFlag==1||showFlag==3">
            <select name="" id="" v-model="couponSelected">
                <option :value="item.mobile_area_code" v-for="(item,index) in languageList" :key="index">{{item.mobile_area_code}}+</option>
            </select>
            <input type="text" class="" v-model="phone" :placeholder="$t('message.phonenum')"/>
        </div>
        <div class="login-group" v-show="showFlag==2||showFlag==4"><input type="text" class="password" v-model="email" :placeholder="$t('message.emailnum')"/></div>
        <div class="login-group-code" v-show="showFlag==3||showFlag==4">
            <input type="text" class="" v-model="code" :placeholder="$t('message.ycode')"/>
            <div class="button" :class="dislebal?'glay':''">
                <span v-show="dislebal">{{count}}s{{$t('message.coderest')}}</span>
                <span v-show="!dislebal" @click="getCode">{{$t('message.getcode')}}</span>
            </div>
        </div>
        <div class="login-group"><input type="password" class="password" v-model="password" :placeholder="$t('message.passnum')"/></div>
        <div class="login-group" v-show="showFlag==3||showFlag==4"><input type="text" class="password" v-model="invitation" :placeholder="$t('message.invitationnum')"/></div>
        <div class="login-group-reg" v-show="showFlag>2">
            <span class="icon_checkbox" @click="checked=!checked" :class="{'checked':checked}"></span>
            {{$t('message.reg1')}}<span class="lik" @click="getLink(22)">{{$t('message.footext')}}</span>{{$t('message.and')}}<span class="lik" @click="getLink(5)">{{$t('message.usearicle')}}</span>
        </div>
        <div class="login-group-btn" @click="getSubmit">{{showFlag>2?$t('message.regbtn'):$t('message.loginbtn')}}</div>
        <div class="login-group-info">
            <van-divider :style="{ color: '#95959e', borderColor: '#eff2f9',  }">
                {{showFlag>2?$t('message.othregtype'):$t('message.othtype')}}
            </van-divider>
        </div>
        <div class="login-group-icon">
            <span class="icon-log email" v-show="showFlag==1" @click="getLoginType(2)"></span>
            <span class="icon-log phone" v-show="showFlag==2" @click="getLoginType(1)"></span>
            <span class="icon-log email" v-show="showFlag==3" @click="getLoginType(4)"></span>
            <span class="icon-log phone" v-show="showFlag==4" @click="getLoginType(3)"></span>
            <span class="icon-log wchart" @click="getOtherLogin('wechart')"></span>
            <span class="icon-log facebooks">
                <fb-signin-button :params="facebookSignInParams" @success="onSignInFacebookSuccess" @error="onSignInFacebookError"></fb-signin-button>
            </span>
            <span class="icon-log icon_google" @click="getOtherLogin('google')"></span>
        </div>
        <div class="login-group-pads" v-if="showFlag>2">{{$t('message.hasacc')}}？<span @click="getLoginType(1)">{{$t('message.loginbtn')}}</span></div>
        <div class="login-group-pads" v-else>
            <b-row>
                <b-col class="left" @click="getLoginType(3)">{{$t('message.newuser')}}</b-col>
                <span class="center">|</span>
                <b-col class="right" @click="getLoginPass()">{{$t('message.fgetpass')}}</b-col>
            </b-row>
        </div>
        <div class="wxrq" v-show="chartQr" id="login_container"></div>
    </b-modal>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,setLocalItem,getLocalItem,web_inter} from '@/untils/common';
export default {
    name:'loginStatus',
    inject:['reload'],
    computed:{
        content: {
            get () {
                return this.$store.state.data.loginStatus;
            },
            set (val) {
                this.$store.commit('setLoginStatus', val)
            }
        },
        language() {
            var params = {
                "lang":this.$store.state.data.languageNameStatus.key?this.$store.state.data.languageNameStatus.key:(localStorage.lang?localStorage.lang:'cn')
            };
            return params;
        },
    },
    data(){
        return{
            web_inter:web_inter,
            timer: null,
            showFlag:1,
            checked:false,
            phone:'',
            code:'',
            dislebal:false,
            count:0,
            email:'',
            password:'',
            invitation:'',
            languageName:'',
            languageObj:{},
            facebookSignInParams: {
                scope: 'email,user_likes',
                return_scopes: true
            },
            googleSignInParams: {
                client_id: web_inter.google.client_id
            },
            params:{
                client_id: web_inter.google.client_id
            },
            renderParams: {
                width: 250,
                height: 50,
                longtitle: true
            },
            chartQr:false,
            languageList:web_inter.languageList,
            couponSelected:''
        }
    },
    watch:{
        language:function() {
            this.getLanguage();
        },
    },
    methods:{
        getLanguage(){
            if(this.language.lang=='cn'){
                this.languageObj = {
                    "emailtip":"请输入您的邮箱",
                    "passwordtip":"请输入您的密码",
                    "phonetip":"请输入您的手机号",
                    "phonecodetip":"请输入您的短信验证码",
                    "seechecktip":"请您阅读并同意37 Vape的隐私政策和使用条款",
                    "codesuccess":"验证码发送成功",
                    "emailrighttip":"请输入正确的邮箱",
                    "phonerighttip":"请输入正确的手机号",
                }
            }else if(this.language.lang=='en'){
                this.languageObj = {
                    "emailtip":"enter your email",
                    "passwordtip":"enter your password",
                    "phonetip":"enter your mobile number",
                    "phonecodetip":"enter your SMS verification code",
                    "seechecktip":"Please read and agree to 37 Vape's Privacy Policy and Terms of Use",
                    "codesuccess":"Verification code sent successfully",
                    "emailrighttip":"Please enter the correct email address",
                    "phonerighttip":"Please enter the correct mobile number",
                }
            }else if(this.language.lang=='it'){
                this.languageObj = {
                    "emailtip":"inserisci la tua email",
                    "passwordtip":"inserisci la password",
                    "phonetip":"inserisci il tuo numero di cellulare",
                    "phonecodetip":"Inserisci il tuo codice di verifica SMS",
                    "seechecktip":"Si prega di leggere e accettare l'Informativa sulla privacy e le Condizioni d'uso di 37 Vape",
                    "codesuccess":"Codice di verifica inviato correttamente",
                    "emailrighttip":"Inserisci l'indirizzo email corretto",
                    "phonerighttip":"Inserisci il numero di cellulare corretto",
                }
            }
        },
        getLink(id){
            this.$store.commit('setLoginStatus',false);
            this.$router.push({path:'/article',query:{id:id}});
        },
        getClose(){
            this.$store.commit('setLoginStatus',false);
        },
        getCode(){
            let self = this;
            self.getLanguage();
            if(self.showFlag==4){//根据邮箱获取邮箱验证码
                if (this.email=='') {
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.email.test(this.email)) {
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else{
                    let params = {
                        email:this.email,//（邮箱）
                        type:6,//（类型：  6邮箱注册 7邮箱忘记密码）
                    }
                    this.$http.api_email_sendcode(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.languageObj.codesuccess,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                    self.dislebal = true;
                                    const TIME_COUNT = 60;
                                    if (!self.timer) {
                                        self.count = TIME_COUNT;
                                        self.timer = setInterval(() => {
                                            if (self.count > 0 && self.count <= TIME_COUNT) {
                                                self.count--;
                                            } else {
                                                clearInterval(self.timer);
                                                self.timer = null;
                                                self.dislebal = false;
                                            }
                                        }, 1000)
                                    }
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else if(self.showFlag==3){//根据手机号获取短信验证码
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.number.test(this.phone)) {
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else{
                    let params = {
                        telno:this.phone,//（手机号）、 
                        type:1,//（类型： 1短信注册 2短信忘记密码）,
                        areacode:this.couponSelected,
                    }
                    this.$http.api_phone_sendcode(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                ElementUI.Message({
                                    message:this.languageObj.codesuccess,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                self.dislebal = true;
                                const TIME_COUNT = 60;
                                if (!self.timer) {
                                    self.count = TIME_COUNT;
                                    self.timer = setInterval(() => {
                                        if (self.count > 0 && self.count <= TIME_COUNT) {
                                            self.count--;
                                        } else {
                                            clearInterval(self.timer);
                                            self.timer = null;
                                            self.dislebal = false;
                                        }
                                    }, 1000)
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    })
                }
            }
        },
        getLoginType(type){
            this.showFlag = type;
            this.phone = '';
            this.code = '';
            this.email = '';
            this.password = '';
            this.timer = null;
            this.count = 0;
            this.dislebal = false;
        },
        getSubmit(){
            this.getLanguage();
            if(this.showFlag==1){// 登录 手机号和密码
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.number.test(this.phone)) {
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                let params = {
                    account:this.phone,
                    password:this.password,
                }
                this.$http.api_phone_login(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                setLocalItem('login_user_info',res.data)
                                this.phone = '';
                                this.password = '';
                                this.$store.commit('setLoginStatus',false);
                                this.$store.commit('setLoginNameStatus', res.data);
                                this.$root.Bus.$emit('userInfoClick', res.data)
                                this.reload();
                                if(res.data.is_company==1){
                                    if(res.data.cinfo.company_name==null||res.data.cinfo.company_name=='') {
                                        this.$router.push({path:'/uinfo',query:{type:3}});
                                    }else{
                                        this.$router.push({path:'/list',query:{type:0}});
                                    }
                                }else{
                                    if(res.data.family_name==null&&res.data.token!=''){
                                        this.$router.push({path:'/uinfo',query:{type:1}});
                                    } 
                                }
                                // if(res.data.family_name==null&&res.data.token!=''){
                                //     if(res.data.is_company==1){
                                //         this.$router.push({path:'/uinfo',query:{type:3}});
                                //     }else {
                                //         this.$router.push({path:'/uinfo',query:{type:1}});
                                //     }
                                // }
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else if(this.showFlag==2){// 登录 电子邮箱和密码
                if (this.email=='') {
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.email.test(this.email)) {
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                let params = {
                    account:this.email,
                    password:this.password,
                }
                this.$http.api_email_login(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                setLocalItem('login_user_info',res.data)
                                this.email = '';
                                this.password = '';
                                this.$store.commit('setLoginStatus',false);
                                this.$store.commit('setLoginNameStatus', res.data);
                                this.$root.Bus.$emit('userInfoClick', res.data)
                                this.reload();
                                // is_company：1是企业用户，0普通用户，（1填写个人资料或2绑定手机号或3填写企业用户资料）
                                if(res.data.is_company==1){
                                    if(res.data.cinfo.company_name==null||res.data.cinfo.company_name=='') {
                                        this.$router.push({path:'/uinfo',query:{type:3}});
                                    }else{
                                        this.$router.push({path:'/list',query:{type:0}});
                                    }
                                }else{
                                    if(res.data.family_name==null&&res.data.token!=''){
                                        this.$router.push({path:'/uinfo',query:{type:1}});
                                    } 
                                }
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else if(this.showFlag==3){// 注册 手机号和验证码和密码
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.number.test(this.phone)) {
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if(this.code==''){
                    ElementUI.Message({
                        message:this.languageObj.phonecodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!this.checked){
                    ElementUI.Message({
                        message:this.languageObj.seechecktip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                let params = {
                    account:this.phone,
                    code:this.code,
                    password:this.password,
                    areacode:this.couponSelected,
                    invitation_code:this.invitation,//企业邀请码
                }
                this.$http.api_phone_register(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.code = '';
                                this.phone = '';
                                this.password = '';
                                ElementUI.Message({
                                    message:res.message,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                this.showFlag = 1;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else if(this.showFlag==4){// 注册 电子邮箱和验证码和密码
                if (this.email=='') {
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if (!jsReg.email.test(this.email)) {
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if(this.code==''){
                    ElementUI.Message({
                        message:this.languageObj.phonecodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!this.checked){
                    ElementUI.Message({
                        message:this.languageObj.seechecktip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                let params = {
                    account:this.email,
                    code:this.code,
                    password:this.password,
                    invitation_code:this.invitation,//企业邀请码
                }
                this.$http.api_email_register(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.code = '';
                                this.email = '';
                                this.password = '';
                                ElementUI.Message({
                                    message:res.message,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                                this.showFlag = 2;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        // 找回密码
        getLoginPass(){
            this.$store.commit('setLoginStatus',false);
            this.$router.push({path:'/passfind',query:{type:this.showFlag}})
        },
        // 完善信息和绑定手机号
        getOtherLogin(type){
            if(type=='wechart'){
                location.href = web_inter.wchart.wchart_url;
            }else if(type=='facebook'){
                
            }else if(type=='google'){
                window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri='+web_inter.google.google_url+'&prompt=consent&response_type=code&client_id='+web_inter.google.client_id+'&scope='+web_inter.google.google_scope+'&access_type=offline'
            }
            this.$store.commit('setLoginStatus',false);
        },
        // facebook登录
        onSignInFacebookSuccess (response) {
            FB.api('/me?fields=id,name,gender,first_name,last_name,email,birthday,hometown,picture,middle_name,short_name', dude => {
                if(response&&response.authResponse&&dude){
                    let clientid = this.web_inter.facebook.client_id;//平台唯一id301546955432179
                    let clientsecret = this.web_inter.facebook.client_secret;//平台唯一秘钥e770ae2bb5737aa0fe9892a249e4bf92
                    let timestamp = new Date().getTime();
                    let fappid = dude.id;//用户在平台唯一userid
                    let fsign = this.$md5(clientid+clientsecret+timestamp+fappid);
                    this.getLoginFeacbook(response,dude,fsign);
                }
            });
        },
        onSignInFacebookError (error) {
            console.log(error)
        },
        getLoginFeacbook(response,dude,fsign){
            let that = this;
            let timestamp = new Date().getTime();
            let fappid = dude.id;//用户在平台唯一userid
            that.$http.api_facebooklogin({
                access_token:response.authResponse.accessToken,//（access_token）
                time:timestamp,//（时间戳）
                id:fappid,//（facebook返回的平台唯一id）
                sign:fsign,//（签名 $fsign = md5($appid+$clientsecret+timestamp+$fid);）
                nickname:dude.name,//（昵称）
                head_pic:dude.picture.data.url,//（头像路径）
                union_id:'',//（唯一id）
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            if(res.data.ubid&&res.data.userinfo.length==0){
                                that.$router.push({path:'/uinfo',query:{type:2,ubid:res.data.ubid}});
                            }else{
                                setLocalItem('login_user_info',res.data.userinfo);
                                if(res.data.userinfo.family_name==null&&res.data.userinfo.token!=''){
                                    that.$router.push({path:'/uinfo',query:{type:1}});
                                }else{
                                    that.$store.commit('setLoginNameStatus', res.data.userinfo);
                                    that.$root.Bus.$emit('userInfoClick', res.data.userinfo)
                                    that.$router.go(0);
                                }
                            }
                            that.reload();
                            that.$store.commit('setLoginStatus',false);
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
    },
    mounted(){
        // 默认显示第一个区号
        this.couponSelected = this.languageList[0].mobile_area_code
    }
}
</script>
<style lang="scss" scoped>
.wxrq{
    width: 200px;
    height: 200px;
}
.fb-signin-button {
    display: inline-block;
    width:24px;
    height:24px;
}
.g-signin-button {
    display: inline-block;
    width:24px;
    height:24px;
}
.login-group{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
    select,input{
        outline:none;
        border:none;
        padding:0 10px;
        background: transparent;
    }
    .password{
        width: 100%;
    }
    .pc-select{
        width: 100%;
        height:58px;
        margin-bottom: 20px;
        padding:0 20px;
        border-radius:5px;
        border:1px solid #DDE0ED;
        position: relative;
        cursor: pointer;
        background: red;
        .pc-select-namediy{
            position: absolute;
            left:0;
            top:0;
            height:58px;
            line-height:58px;
            width: 100%;
            padding:0 20px;
        }
        .pc-select-diy{
            position: absolute;
            left:0;
            top:56px;
            z-index:1;
            min-height:100px;
            width: 100%;
            border-radius:5px;
            border:1px solid #DDE0ED;
            width: 100%;
            background: #fff;
            .diylist{
                padding:10px 20px;
                background: #fff;
                border-bottom:1px solid #DDE0ED;
                &:last-child{
                    border-bottom:none; 
                }
            }
        }
    }
}
.login-group-btn{
    cursor: pointer;
    background: #222;
    color: #fff;
    text-align: center;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
}
.login-group-icon{
    text-align: center;
    padding:0px 0 10px;
    .icon-log{
        cursor: pointer;
        display: inline-block;
        width:24px;
        height:24px;
        margin: 0 4px;
        .g-signin2{
            display: inline-block;
            width:24px;
            height:24px;
        }
    }
    .email{
        background: url(~@/assets/images/icon_email.png) no-repeat left top;
        background-size:100% 100%;
    }
    .phone{
        background: url(~@/assets/images/icon_phone.png) no-repeat left top;
        background-size:100% 100%;
    }
    .wchart{
        background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
        background-size:100% 100%;
    }
    .facebooks{
        background: url(~@/assets/images/icon_facebooks.png) no-repeat left top;
        background-size:100% 100%;
    }
    .icon_google{
        background: url(~@/assets/images/icon_google.png) no-repeat left top;
        background-size:100% 100%;
    }
}
.login-group-reg{
    padding:0px 0 10px;
    vertical-align: middle;
    .icon_checkbox{
        float: left;
        display: block;
        width:20px;
        height:20px;
        background:url(~@/assets/images/icon_check.png) no-repeat left top;
        background-size: 100% 100%;
        margin-top:1px;
        margin-right:8px;
        cursor: pointer;
        border-radius: 3px;
    }
    .checked{
        background:url(~@/assets/images/icon_checked.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .lik{
        cursor: pointer;
        color: #1F73B7;
        font{
            color: #2E2E2E;
        }
    }
}
.login-group-code{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    margin-bottom: 20px;
    display: flex;
    -webkit-box-orient: horizontal;
    position: relative;
    input{
        width:51%;
        padding:10px 10px;
        outline:none;
        border:none;
        background: transparent;
    }
    .button{
        position: absolute;
        top: 0;
        right:0;
        // width: 30%;
        padding:10px;
        text-align: center;
        color: #fff;
        border-radius:4px;
        background: #1F73B7;
        cursor: pointer;
        span{
            color: #fff;
        }
    }
    .glay{
        background: #9a9a9a;
    }
}
.login-group-pads{
    
    padding:10px 0;
    text-align: center;
    span{
        cursor: pointer;
        color:#1F73B7;
    }
    .left{
        cursor: pointer;
        text-align: left;
        color:#1F73B7;
        padding-right: 0;
        text-decoration: underline;
    }
    .center{
        text-align: center;
    }
    .right{
        cursor: pointer;
        text-align: right;
        color:#1F73B7;
        padding-left: 0;
        text-decoration: underline;
    }
}
.lik{
    color:#1F73B7;
}
/deep/.modal-header .close{
    padding:0 ;
    font-size:28px;
    font-weight: normal;
    margin: -1rem -1rem -1rem auto;
}
/deep/.el-divider__text{
    padding:0 10px;
}
/deep/.el-divider__text.is-center{
    left: 49%;
    color:#95959E;
}
// 模态窗样式调整设计图模样
@media (min-width: 576px){
    /deep/.modal-sm {
        max-width: 500px; 
    }
}
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 25px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-bottom:20px;
    padding-left:0px;
    padding-right:0px;
    border-bottom:none;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
    .modal-title{
        width: 100%;
        text-align: center;
        font-size:22px;
    }
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
</style>