<template>
    <!-- 年龄提示弹窗 -->
    <b-modal size="sm" centered v-model="tipleStatus" :no-close-on-backdrop="true" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" hide-footer hide-header-close :title="$t('message.agetxt1')">
        <div class="login-group-txts">
            <div class="">{{$t('message.agetxt2')}}</div>
            <div class="login-group-txts">{{$t('message.agetxt3')}}</div>
        </div>
        <div class="login-group-btn" @click="getAge">{{$t('message.agetxt4')}}</div>
        <div class="login-group-btns" @click="getGoogle">{{$t('message.agetxt5')}}</div>
    </b-modal>
</template>
<script>
import {setLocalItem} from '@/untils/common';
export default {
    name:'tipleStatus',
    computed:{
        tipleStatus: {
            get () {
                return this.$store.state.data.tipleStatus;
            },
            set (val) {
                this.$store.commit('setTipleStatus', val)
            }
        },
    },
    data(){
        return{
        }
    },
    methods:{
        // 已满18+
        getAge(){
            setLocalItem('yooz_age',18);
            this.$store.commit('setTipleStatus',false);
        },
        // 未满18岁，跳转至谷歌搜索页
        getGoogle(){
            location.href = 'https://www.google.com/';
        }
    },
    mounted(){
        
    }
}
</script>
<style lang="scss" scoped>
.login-group-txts{
    padding:0px 0 10px;
}
.login-group-btn{
    cursor: pointer;
    background: #222;
    border: 1px solid #222;
    color: #fff;
    text-align: center;
    border-radius:4px;
    padding:10px 0;
    margin: 0 10px;
    margin-bottom: 20px;
    
}
.login-group-btns{
    cursor: pointer;
    padding:10px 0;
    text-align: center;
    border-radius:4px;
    margin: 0 10px;
    border: 1px solid #222;
    
}
// 模态窗样式调整设计图模样
@media (min-width: 576px){
    /deep/.modal-sm {
        max-width: 500px; 
    }
}
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 25px 20px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-left:0px;
    padding-right:0px;
    border-bottom:none;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
</style>