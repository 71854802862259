module.exports = {
    nameReg: /^([\u0391-\uFFE5]|(·?)|[\u0391-\uFFE5]){2,12}$/, //姓名校验 中英文 A-Za-z
    phoneReg: /^1\d{10}$/,//电话号码正则表
    phoneOtherReg:/^[1][0-9]{10}$/,//电话号码校验
    phoneRegs:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,
    mailReg: /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/,//邮箱校验
    idnoReg:/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,//身份证校验
    addressregEn:/[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,//特殊符号校验
    addressregCn:/[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,//特殊符号校验
	codeReg: /^\d{6}$/,  //短信验证码
	allNum: /^\d+$/,  //短信验证码
    lowerCase: /^[a-z]+$/,// 小写字母
    upperCase: /^[A-Z]+$/, // 大写字母
    numberReg: /^[0-9]+$/, // 数字
    weight_height: /^[1-9]{1}[0-9]{1,2}$/,
    postCodeReg: /^[0-9]\d{6}/,//邮编校验
}