
const header = () => import('@/components/header/header');
const footer = () => import('@/components/footer/footer');
const cooperation = () => import(/* webpackChunkName: "cooperation" */ '@/pages/cooperation/cooperation');//合作机会
export default [
    {
        path: '/cooperation',
        name: 'cooperation',
        meta: {
            keepAlive: true,
        },
        components: {
            default: cooperation,
            header:header,
            footer:footer,
        }
    }
];
