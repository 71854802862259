import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/i18n'
import {Http} from './untils/http.js';//接口处理
import './assets/scss/global.scss';
import '@/untils/filter'; //过滤器函数
import 'babel-polyfill';
import 'url-search-params-polyfill';
import "classlist-polyfill";
import promise from 'es6-promise';
promise.polyfill();
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
// import VueGapi from 'vue-gapi'
// Vue.use(VueGapi, {
//   apiKey: 'AIzaSyB9GWUSCxPAZSrWqdtsUsYbFjsDN6Afx2c',
//   clientId: '680460682360-db1lfshm428pt371cl02r7l5qp8i4d4u.apps.googleusercontent.com',
//   discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
//   scope: 'https://www.googleapis.com/auth/spreadsheets',
// })
import FBSignInButton from 'vue-facebook-signin-button'
Vue.use(FBSignInButton)
import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton);
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// const {google} = require('googleapis');
// console.log(google)
// Each API may support multiple versions. With this sample, we're getting
// v3 of the blogger API, and using an API key to authenticate.
// const blogger = google.blogger({
//   version: 'v3',
//   auth: 'AIzaSyB9GWUSCxPAZSrWqdtsUsYbFjsDN6Afx2c'
// });

// const params = {
//   blogId: '3213900'
// };

// get the blog details
// blogger.blogs.get(params, (err, res) => {
//   if (err) {
//     console.error(err);
//     throw err;
//   }
//   console.log(`The blog url is ${res.data.url}`);
// });

// import GoogleSignInButton from 'vue-google-signin-button-directive'
// Vue.use(GoogleSignInButton)
// Vue.directive('google-signin-button', {
//   bind: function (el, binding, vnode) {
//     CheckComponentMethods()
//     let clientId = binding.value
//     let googleSignInAPI = document.createElement('script')
//     googleSignInAPI.setAttribute('src', 'https://apis.google.com/js/api:client.js')
//     document.head.appendChild(googleSignInAPI)

//     googleSignInAPI.onload = InitGoogleButton

//     function InitGoogleButton() {
//       gapi.load('auth2', () => {
//         const auth2 = gapi.auth2.init({
//           client_id: clientId,
//           cookiepolicy: 'single_host_origin'
//         })
//         auth2.attachClickHandler(el, {},
//             OnSuccess,
//             Onfail
//         )
//       })
//     }
//     function OnSuccess(googleUser) {
//       vnode.context.OnGoogleAuthSuccess(googleUser.getAuthResponse().id_token)
//       googleUser.disconnect()
//     }
//     function Onfail(error) {
//       vnode.context.OnGoogleAuthFail(error)
//     }
//     function CheckComponentMethods() {
//       if (!vnode.context.OnGoogleAuthSuccess) {
//         throw new Error('The method OnGoogleAuthSuccess must be defined on the component')
//       }

//       if (!vnode.context.OnGoogleAuthFail) {
//         throw new Error('The method OnGoogleAuthFail must be defined on the component')
//       }
//     }
//   }
// })




// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole);
// import 'vue-googlemaps/dist/vue-googlemaps.css'
// import VueGoogleMaps1 from 'vue-googlemaps'
// Vue.use(VueGoogleMaps1, {
//  load: {
//   apiKey: 'AIzaSyCDNBuNBH5BRGCr5JQiziIqvTCTJSbB5uo',
//   libraries: ['places'],
//   useBetaRenderer: false,
//  },
// })

// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyB9GWUSCxPAZSrWqdtsUsYbFjsDN6Afx2c', // 此处填入谷歌地图申请的key
//     libraries: 'places'
//   }
// })


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import iView from 'iview';
import 'iview/dist/styles/iview.css';
Vue.use(iView);
import water from "vue-waterfall2";//瀑布流布局
Vue.use(water);
import { 
    BootstrapVue, 
    IconsPlugin 
} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import 'vant/lib/index.css';
import {
    Form,
    Field,
    Cell, 
    CellGroup,
    Tab, 
    Tabs,
    Toast,
    Popup,
    Step,
    Steps,
    Rate,
    Collapse,
    CollapseItem,
    Swipe, 
    SwipeItem,
    Divider
} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Rate);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Divider);
Vue.config.productionTip = false;
Vue.prototype.$http = Http;
// Vue.prototype.$VueGapi = VueGapi;

router.afterEach(() => {
	window.scrollTo(0,0);
});
new Vue({
    data:{
      Bus:new Vue()
    },
    // created () { // Google Sign-in initial 
    //   window.gapi.load('auth2' , () => {
    //     window.gapi.auth2.init({        
    //       client_id: '680460682360-db1lfshm428pt371cl02r7l5qp8i4d4u.apps.googleusercontent.com' ,       
    //     });     
    //   });   
    // }, 
    router,
    store,
    i18n,   //把 i18n 挂载到 vue 根实例上
    render: h => h(App)
}).$mount('#app')
